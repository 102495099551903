import axios from '../http'
const qs = require('qs');

const requset = function({ method = 'get', url = '', isLoading = false, params = {}, ...rest }) {
  return new Promise((resolve, reject) => {
    // axios.isLoading = isLoading
    if (method.toUpperCase() === 'GET') {
      axios.get(url, {
        params: params,
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        }, rest).then(res => {
        resolve(res)
      })
    } else if (method.toUpperCase() === 'POST') {
      axios.post(url, params, rest).then(res => {
        resolve(res)
      })
    } else if (method.toUpperCase() === 'PUT') {
      axios.put(url, params, rest).then(res => {
        resolve(res)
      })
    } else if (method.toUpperCase() === 'DELETE') {
      axios.delete(url, params, rest).then(res => {
        resolve(res)
      })
    }
  })
}

export default requset
