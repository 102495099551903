<template>

    <!-- 菜单 -->
    <el-drawer
      class="custom-dialog-menu"
      ref="dialog"
      title=""
      :visible.sync="showMenuFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="showHeader"
      :destroy-on-close="true"
      :show-close="false"
      :wrapperClosable="false"
      :size="drawerWidth"
      @close="close"
      direction="ltr"
      :z-index="1000"
      >
      <div class="custom-dialog-menu-slider-box">
        <div class="custom-dialog-menu-slider-box-item" :class="{'custom-dialog-menu-slider-box-active': sliderBoxActive == 0}" @click="sliderBoxActive = 0;$router.push('/home');setActiveMenuItem('1');close();">创作空间</div>
        <div class="custom-dialog-menu-slider-box-item" :class="{'custom-dialog-menu-slider-box-active': sliderBoxActive == 1}" @click="sliderBoxActive = 1;$router.push('/bookbreakdown/index');setActiveMenuItem('2');close();">拆文学习</div>
        <div class="custom-dialog-menu-slider-box-item mali-disabled" :class="{'custom-dialog-menu-slider-box-active': sliderBoxActive == 2}" @click="sliderBoxActive = 2;$router.push('/bookbreakdown/index');close();">素材宝库(敬请期待)</div>
        <div class="custom-dialog-menu-slider-box-item mali-disabled" :class="{'custom-dialog-menu-slider-box-active': sliderBoxActive == 3}" @click="sliderBoxActive = 3;$router.push('/bookbreakdown/index');close();">灵感源泉(敬请期待)</div>
      </div>
    </el-drawer>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'malimenu',
  components: {

  },
  data() {
    return {
      sliderBoxActive: 0
    }
  },
  mounted() {
    console.log('mounted');
  },
  computed: {
    ...mapState(['showMenuFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '50%' : '50%';
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setShowMenuFlag','setActiveMenuItem']),
    close(){
      this.setShowMenuFlag(false)
    },
    open(){
      this.setShowMenuFlag(true)
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
.custom-dialog-menu {
  ::v-deep .el-drawer__wrapper {
    z-index: 1000 !important;
  }
  ::v-deep .el-drawer {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  ::v-deep .el-drawer__body {
    margin-top: 2rem;
  }
  &-slider-box {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem;
    background: #E5E7FC;
    color: rgba(0, 0, 0, .8);
    border-radius: 0 20px 20px 0;
    height: 80%;
    &-item {
      border-radius: 20px;
      // flex:1;
      line-height: 2rem;
      cursor: pointer;
      margin: .25rem .25rem .25rem 2rem ;
      padding: 0 1rem;
      font-size: 14px;
    }
    &-active {
      background: #ffffff;
      color: #366EF4;
    }
  }
}
</style>
