<template>
<div :style="{'width': drawerWidth}">
<!-- <el-row>
  <el-col :span="drawerWidth"> -->
    <el-upload
      drag
      class="ly-uploader"
      action="#"
      :before-upload="handleBeforeUpload"
      :on-preview="handlePreview"
      :on-success="handlePreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      :on-progress="handleProgress"
      :on-change="handleChange"
      :on-exceed="handleExceed"
      list-type="text"
      accept=".txt"
      :limit="limit"
      :http-request="httpRequest">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div style="line-height: 1rem !important;color: rgba(0, 0, 0, .6);" class="el-upload__tip" slot="tip">仅支持<span style="color: #125FDC;padding: 0 .25rem;">txt</span>文件，字数不超过3万，文件不超过<span style="color: #125FDC;padding: 0 .25rem;">10MB</span></div>
    </el-upload>
    <!-- <el-progress style="visibility: hidden;" :percentage="progressPercent"></el-progress> -->
    <!-- <el-progress v-else-if="progressPercent < 50" :percentage="progressPercent" status="exception"></el-progress>
    <el-progress v-else-if="progressPercent < 70" :percentage="progressPercent" status="warning"></el-progress>
    <el-progress v-else-if="progressPercent <= 100" :percentage="progressPercent" status="success"></el-progress> -->
<!--  </el-col>
</el-row> -->
</div>
</template>

<script>
import  * as AliossApi from '@/api/alioss'
const OSS = require('ali-oss');
export default {
  name: 'RichEditor',
  props: {
    picSize: {
      type: String,
      default: '160x160'
    },
    url: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    if(this.url){
      this.url.split(',').forEach((item,inex)=>{
        this.fileList.push({name: '', url: item})
      })
    }
  },
  computed: {
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : '80%';
    },
  },
  data() {
    return {
        fileList: [],
        progressPercent: 0
    }
  },
  methods: {
    handleProgress(event, file, fileList) {
      console.log('handleProgress', event, file);
      this.progressPercent = Math.floor(event.percent);
    },
    handleChange(file, fileList){
      console.log('handleChange', file, fileList);
    },
    async handleExceed(files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      let p1 = Object.assign({}, files)
      let p2 = Object.assign({}, fileList)
      console.log('handleExceed', p1, p2);
      if(p1[0].name == p2[0].name && p1[0].size == p2[0].size){
        return
      }
      if (fileList.length >= 1) {
        fileList.splice(0, 1, files[0]);
      }
      this.fileList = fileList;
      this.httpRequest({file: this.fileList[0]})
    },
    readAsTextSync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsText(file);
      });
    },

    // 使用 async/await 语法来“同步”读取文件
    async readFileAndCountChineseCharacters(file) {
      try {
        const content = await this.readAsTextSync(file);
        const count = content.length;
        console.log('中文字数：', count);
        return count
      } catch (error) {
        console.error('读取文件时出错：', error);
      }
    },
    async httpRequest(item) {
      console.log('httpRequest', item);
      var file = item.file

      // 文件大小
      const maxSize = 10 * 1024 * 1024; // 10m
      // const maxSize = 10 * 1024; // 10k
      if (file.size > maxSize) {
        this.fileList = []
        this.$message.error('文件大小不能超过 10 MB');
        return false; // 阻止上传
      }
      // 字数
      let wordCount = 0
      if(file){
        wordCount = await this.readFileAndCountChineseCharacters(file);
      }
      console.log('字数2：', wordCount);
      if (wordCount > 30000) {
        this.fileList = []
        this.$message.error('字数不超过3万');
        return false; // 阻止上传
      }

      let fileType = file.type.toLowerCase()
      let ext=''
      if(fileType.indexOf('text') >= 0){
        ext = '.txt';
      }else if(fileType.indexOf('zip') >= 0){
        ext = '.zip';
      }

      let storeAs = process.env.VUE_APP_CURRENTMODE + '/' + Number(new Date().getTime()/(3600*24*7*1000)).toFixed(0) + '/' + this.guid().replace(/-/g,'') + ext
      console.log(storeAs);

      let res = await AliossApi.ststoken()
      const result = res.data.data
      const client = new OSS({
        accessKeyId: result.accessKeyId,
        accessKeySecret: result.accessKeySecret,
        stsToken: result.stsToken,
        endpoint: result.endpoint,
        bucket: result.bucket
      })
      client.multipartUpload(storeAs, file, {
        progress: (percentage) => {
          console.log(`上传进度：${percentage * 100}% file: `, file);
        }
      }).then(result => {
        console.log('multipartUpload result', result);
        let picUrl = result.res.requestUrls[0];
        this.fileList = [{name: file.name, url: picUrl}]
        console.log('picUrl', picUrl, this.fileList);

        let url =  picUrl.split("?")[0];
        if (url != null && url.length > 0) {
          // 上传成功
          console.log('上传结束：', this.$parent.$parent.$parent.$parent);
          if(this.$parent.$parent.$parent.$parent.getFile){
            this.$parent.$parent.$parent.$parent.getFile();
          }
        } else {
          this.$message.error("文件上传失败")
        }
      }).catch(function(err) {})
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      let temp = []
      this.fileList = temp.concat(fileList)
      if(this.$parent.$parent.$parent.$parent.getFile){
        this.$parent.$parent.$parent.$parent.getFile();
      }
    },
    handlePreview() {
      console.log('handlePreview', 1);
      // picUrl
    },
    getFile() {
      if(this.fileList.length > 0) {
        return this.fileList[0]
      } else {
        return {};
      }
    },
    async handleBeforeUpload(file) {

      return true; // 允许上传
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
       (c) => {
          var r = (Math.random() * 16) | 0
          var v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        })
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-upload {
  width: 100% !important;
}
/deep/ .el-upload-dragger {
  width: 100% !important;
}

</style>
