<template>
<div>
    <div class="news-box" :class="{'news-box-pc': !isMobile, 'news-box-mobile': isMobile}">
      <div class="news-box-last" :class="{'news-box-last-pc': !isMobile, 'news-box-last-mobile': isMobile}" >
        <img src="../assets/icon-zxdt-1.png" style="height: 36px;padding-top: 5px;padding-bottom: 5px;" />
        <span class="news-box-last-title" style="white-space: nowrap;">最新动态</span>
        <div class="news-box-last-content" style="display: inline-block;">
          <el-carousel style="height: 20px;line-height: 20px;width: 100%;text-wrap: nowrap;" direction="vertical" :autoplay="true" :loop="true" indicator-position="none">
            <el-carousel-item v-for="(item, index) in dongtai" :key="index">
              <a :title="item.title" style="text-wrap: nowrap;text-overflow: ellipsis;font-size: 12px;">{{ item.title }}</a>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="news-box-contribute" style="">
        <img src="../assets/zhinanzhen.png" style="height: 36px;padding-top: 6px;padding-bottom: 5px;" />
        <span class="news-box-contribute-title" style="white-space: nowrap;">创作指南</span>
        <!-- <span class="news-box-contribute-content">言情/千字20元/10000-15000字</span> -->
        <div class="news-box-contribute-content" style="display: inline-block;flex: 1;min-width: 20rem;">
          <el-carousel style="height: 20px;line-height: 20px;width: 100%;" :interval="5000" direction="vertical" :autoplay="true" :loop="true" indicator-position="none">
            <el-carousel-item v-for="(item, index) in tougao" :key="index">
              <a :title="item.title" style="text-wrap: nowrap;text-overflow: ellipsis;font-size: 12px;" target="_blank" :href="item.linkUrl">{{ item.title }}</a>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- 三个div块元素，在大屏下排成一行，小屏下，每个元素一行 -->
    <div class="mly-container" style="margin-bottom: 1.5rem;">
      <!-- 长篇小说 -->
      <div class="mly-box">
        <div class="mly-half mly-border-radius-30" @click="handleGen('genbychangpian')"
          style="background: linear-gradient( 185deg, #DEEBFF 0%, #AFCCFE 100%);">
          <div class="mly-title">
            长篇小说
          </div>
          <div class="mly-subtitle mly-border-radius-20" style="background: linear-gradient( 90deg, #366EF4 0%, #749DFF 100%);">
            快速高效专业
          </div>
          <div class="mly-detail d-flex flex-between">
            <div class="d-flex" style="align-items: center;">
              <div class="mly-shangsheng d-flex">
                <img style="height: 15px;" src="@/assets/icon-shangsheng.png" />
                <div>80%</div>
              </div>
              <div class="mly-detail-text">
                十分钟生成长篇开篇及续写
              </div>
            </div>
            <div class="d-flex" style="align-items: center;">
              <img style="height: 16px;" src="@/assets/icon-youjiantou.png" />
            </div>
          </div>
        </div>
        <!-- 短篇小说 -->
        <div class="mly-half mly-border-radius-30" @click="handleGen('genbyzhihu')"
          style="background: linear-gradient( 186deg, #E0E1FF 0%, #B2BBFF 100%);">
          <div class="mly-title">
            短篇小说
          </div>
          <div class="mly-subtitle mly-border-radius-20" style="background: linear-gradient( 90deg, #8489FA 0%, #ACB2FF 100%);">
            专业爆款模版
          </div>
          <div class="mly-detail d-flex flex-between">
            <div class="d-flex" style="align-items: center;">
              <div class="mly-shangsheng d-flex">
                <img style="height: 15px;" src="@/assets/icon-shangsheng.png" />
                <div>50%</div>
              </div>
              <div class="mly-detail-text">
                二十分钟生成爆款套路小说，保姆级大神写作思维训练
              </div>
            </div>
            <div class="d-flex" style="align-items: center;">
              <img style="height: 16px;" src="@/assets/icon-youjiantou.png" />
            </div>
          </div>
        </div>
      </div>
      <!-- 超短篇小说 -->
      <div class="mly-box-x">
        <div class="mly-left mly-box-y">
          <!-- 超短篇小说 -->
          <div class="mly-half mly-border-radius-30" @click="handleGen('genbyidea')"
            style="background: #FFFFFF;">
            <div class="mly-title">
              超短篇小说
            </div>
            <div class="mly-subtitle mly-border-radius-20" style="background: linear-gradient( 88deg, #366EF4 0%, #749DFF 100%);">
              一句话创意生成
            </div>
            <div class="mly-detail d-flex flex-between">
              <div class="d-flex" style="align-items: center;">
                <div class="mly-shangsheng d-flex" style="background: linear-gradient( 45deg, #CFE2FF 0%, #B3CFFF 100%);">
                  <img style="height: 15px;" src="@/assets/icon-shangsheng.png" />
                  <div>90%</div>
                </div>
                <div class="mly-detail-text">
                  彩虹同人
                </div>
              </div>
              <div class="d-flex" style="align-items: center;">
                <img style="height: 16px;" src="@/assets/icon-youjiantou.png" />
              </div>
            </div>
          </div>
          <!-- 短篇定制 -->
          <div class="mly-half mly-border-radius-30" @click="handleGen('genbyoutline')"
            style="background: linear-gradient( 197deg, #B7C0FF 0%, #647EFF 100%);">
            <div class="mly-title" style="color: #FFFFFF;">
              短篇定制
            </div>
            <div class="mly-subtitle mly-border-radius-20" style="background: linear-gradient( 90deg, #8488FA 0%, #ACB2FF 100%);">
              已有章纲生定制
            </div>
            <div class="mly-detail d-flex flex-between">
              <div class="d-flex" style="align-items: center;">
                <div class="mly-shangsheng d-flex">
                  <img style="height: 15px;" src="@/assets/icon-shangsheng.png" />
                  <div>60%</div>
                </div>
                <div class="mly-detail-text" style="color: #FFFFFF;">
                  专业定制
                </div>
              </div>
              <div class="d-flex" style="align-items: center;">
                <img style="height: 16px;" src="@/assets/icon-youjiantou.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="mly-right" style="flex: 1;" @click="handleGen('chaishu')">
          <!-- 拆书 -->
          <div class="mly-half mly-border-radius-30" style="height: 100%;background: #FFFFFF;">
            <div class="mly-title">
              拆书<span style="color: #FFFFFF;">学习中</span>
            </div>
            <div class="mly-subtitle mly-border-radius-20" style="background: linear-gradient( 90deg, #8488FA 0%, #ACB2FF 100%);">
              爆款拆析学习
            </div>
            <div class="mly-detail d-flex flex-between" style="align-items: top;">
              <div class="d-flex" style="">
                <div class="mly-shangsheng d-flex" style="height: 24px;background:linear-gradient( 45deg, #CFE2FF 0%, #B3CFFF 100%);">
                  <img style="height: 15px;" src="@/assets/icon-shangsheng.png" />
                  <div>80%</div>
                </div>
                <div class="mly-detail-text" style="width: 58px;white-space: normal;">
                  快速跟风必备神器
                </div>
              </div>
              <div class="d-flex" style="height: 36px;">
                <img style="height: 16px;" src="@/assets/icon-youjiantou.png" />
              </div>
            </div>
            <!-- 虚线 -->
            <hr class="mly-hr-dotted2" style="margin-top: 20px;">

            <div class="mly-box-y" style="margin-left: 7px;font-size: 12px;">
              <div class="mly-half d-flex flex-between" style="align-items: center;cursor: default;padding: 8px 0 0 0; box-shadow: none;">
                <div class="d-flex" style="align-items: center;">
                  <img style="height: 20px; margin-right: 3px; margin-left: -7px;" src="@/assets/icon-dot-layers.png"/>
                  <div class="" style="padding-left: 10px;">
                    快速分析
                  </div>
                </div>
                <div class="">
                  <img style="height: 12px;" src="@/assets/icon-duigou.png"/>
                </div>
              </div>

              <div class="mly-half d-flex flex-between" style="align-items: center;cursor: default;padding: 8px 0 0 0; box-shadow: none;">
                <div class="d-flex" style="align-items: center;">
                  <img style="height: 6px; margin-right: 10px;" src="@/assets/icon-dot.png"/>
                  <div class="" style="padding-left: 10px;">
                    精细章纲
                  </div>
                </div>
                <div class="">
                  <img style="height: 12px;" src="@/assets/icon-duigou.png"/>
                </div>
              </div>

              <div class="mly-half d-flex flex-between" style="align-items: center;cursor: default;padding: 8px 0 0 0; box-shadow: none;">
                <div class="d-flex" style="align-items: center;">
                  <img style="height: 6px; margin-right: 10px;" src="@/assets/icon-dot.png"/>
                  <div class="" style="padding-left: 10px;">
                    丰富技巧
                  </div>
                </div>
                <div class="">
                  <img style="height: 12px;" src="@/assets/icon-duigou.png"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- 短篇模板 -->
      <div class="mly-box-x mly-border-radius-30" style="background: linear-gradient( 180deg, #E1E1FF 0%, #B4BDFF 100%);gap: 0;">
        <div class="mly-full" style="">
          <el-carousel class="custom-carousel" :interval="5000" direction="vertical" :autoplay="true">
            <el-carousel-item v-for="(item, index) in storyTemplates" :key="item.id">
              <div class="muban-box-item pointer" style="width: 85%;" @click="handleTemplateClick(item)">
                  <div class="mly-title">
                    <div style="font-weight: 400;font-size: 12px;color: rgba(0,0,0,0.5);white-space: nowrap;padding-bottom: 6px;padding-top: 10px;">短篇爆款模版<span style="color: #FFFFFF;">发发发发发发发发发发发发发发发发发发发发</span> </div>
                    <div class=""> {{item.title}} </div>
                  </div>

                  <div class="muban-box-item-tag">
                    <span class="muban-box-item-tag-item" :key="indexTag" v-for="(itemTag,indexTag) in item.tag&&item.tag.split(',')">{{itemTag}}</span>
                  </div>

                  <div class="score-box d-flex" style="padding: .5rem 0;align-items: center;">
                    <div class="score-info d-flex" style="flex-direction: column;margin-right: 1rem;">
                      <div class="score-text" style="font-weight: 400;font-size: 12px;">
                        模板难度
                      </div>
                      <div class="score-number" style="font-weight: 600;font-size: 32px;color: rgba(0,0,0,0.8);">
                        {{Number(item.score).toFixed(1)}}
                      </div>
                    </div>
                    <div class="score-star">
                      <div class="muban-box-item-score d-flex" style="">
                        <div v-for="number in 5" :key="number" >
                          <img style="width: 16px; height: 16px;" v-if="number <= item.score/2" src="../assets/star-yellow.png" alt="" />
                          <img style="width: 16px; height: 16px;" v-else-if="number == (item.score/2+0.5)" src="../assets/star-half.png" alt="" />
                          <img style="width: 16px; height: 16px;" v-else src="../assets/star-white.png" alt="" />
                        </div>
                      </div>
                      <div class="score-desc" style="font-size: 12px;line-height: 32px;">
                        {{item.scoreDesc}}
                      </div>
                    </div>
                  </div>

                  <div class="muban-box-item-content">
                    <span style="font-weight: 600;">模板套路：</span><span>{{item.content}}</span>
                  </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

      </div>
    </div>

    <div class="d-flex flex-between flex-y-center" style="border-radius: 20px 0px 0px 0px;width: 100%;">
      <div class="d-flex flex-between flex-y-center" style="width:100%;">
        <div class="d-flex">
          <div class="ly-story-gen-type pointer" :style="{'background': genTypeActive==item.name?'#FFFFFF':'linear-gradient( 270deg, #F2F3FF 0%, #DEE4FF 100%)','color': genTypeActive==item.name?'#366EF4':'#000000', 'font-weight': genTypeActive==item.name?'600':''}"
           style="padding: .6rem 1.2rem .5rem 1.2rem;
            border-radius: 12px 12px 0px 0px;font-size: .8rem;"
                v-for="(item,index) in enums.ArticleGenTypeEnum"
                @click.stop="handlePage(1,item)"
                :key="index" >
                    {{item.desc+'小说'}}
          </div>
        </div>
        <div class="">
          <span style="margin-left: 0.5rem;" v-if="showStateFlag">
            <el-select style="width: 6rem;" class="custom-select"
                v-model="queryForm.articleState"
                filterable
                size="mini"
                placeholder="完成状态">
              <el-option
                v-for="(item,index) in enums.ArticleStateEnum"
                @click.native.stop="handlePage()"
                :label="item.desc"
                :key="index"
                :value="item.name"
              />
            </el-select>
          </span>
          <span style="display: inline-block;margin-left: 0.5rem; ">
            <el-input suffix-icon="el-icon-search" class="search-title custom-select" v-model="queryForm.title" @input="handlePage()" placeholder="输入名称搜索" clearable>
            </el-input>
          </span>
        </div>
      </div>
    </div>
    <div class="writer-story-card-box" v-if="data.length>0" style="position: relative;">
      <el-card class="writer-story-card" style="position: relative;"
         v-for="(item,index) in data" :key="index"
          @click.native.stop="itemClick(item)">
            <div class="word-count-tail" :class="{'word-count-tail-dp': item.articleGenType == 'GEN_BY_ZHIHU' || item.articleGenType == 'GEN_BY_OUTLINE','word-count-tail-cdp': item.articleGenType == 'GEN_BY_IDEA' || item.articleGenType == 'GEN_BY_CHANGPIAN'}">
              {{ getTailByGenType(item) }}
            </div>
            <div class="writer-story-card-title-header d-flex" style="justify-content: space-between;margin-top: 1rem;">
              <div class="ellipsis2 writer-story-card-title d-flex-1">
                <!-- <span>【{{item.articleTypeLabel}}】</span> -->
                <span v-if="item.title">{{ item.title }}</span>
                <span v-else style="color: #999;">该故事还没有名字</span>
              </div>
            </div>
            <div class="d-flex" style="margin-top: .5rem;">
              <div style="background: #F1F2FF;border-radius: 20px;font-size: 12px;color: #366EF4;padding: .25rem .5rem;">{{item.articleGenTypeLabel}}</div>
              <div style="margin-left:.5rem;background: #F1F2FF;border-radius: 20px;font-size: 12px;color: #366EF4;padding: .25rem .5rem;">{{item.articleTypeLabel}}</div>
              <div style="margin-left:.5rem;background: #F1F2FF;border-radius: 20px;font-size: 12px;padding: .25rem .5rem;" v-if="item.articleGenType!='GEN_BY_CHANGPIAN'" :style="{'color': item.articleState=='COMPLETED'?'#366EF4':'#A6A3A3'}">{{item.articleStateLabel}}</div>
            </div>
            <div class="" style="display: flex;align-items: center;justify-content: space-between;margin-top: .5rem;">
              <div class="writer-story-card-time">
                <span class="writer-story-card-time-label">最近编辑</span>
                <span>{{ $time.formatTime(new Date(item.updateTime).getTime()) }}</span>
              </div>
              <div class="writer-story-card-menu">
                <el-dropdown @click.native.stop :trigger="isMobile ? 'click':'hover'" >
                  <span><img style="width: 1rem;" src="@/assets/writer-more.png" /></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <div class="writer-story-card-menu-delete d-flex flex-y-center pointer" @click.stop="handleRemove(item)">
                        <img style="width: .8rem;" src="@/assets/writer-delete.png"/>
                        <span>删除</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
      </el-card>
    </div>
    <el-empty class="data-empty" v-else image="https://www.creatimix.com/static/empty.jpg" description="马上登录开始创作之旅!" style="height: auto;margin: 0 auto;background-color: #FFFFFF;">
      <p style="margin: 0;color: rgba(0,0,0,0.6);font-size: 14px;">
        <el-button style="border-radius: 6px !important;padding: 0.5rem 2rem;margin-top: 1rem;margin-bottom: .5rem;" size="small" type="primary" @click="setShowLoginDialogFlag(true)">注册/登录</el-button>
      </p>
    </el-empty>

    <el-row v-if="data.length>0">
      <el-col>
        <div class="pagination">
          <el-pagination background
            hide-on-single-page
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-col>
    </el-row>

    <StoryIdeaDialog v-if="showDialogCdpFlag" :enums="enums" channel="workspace_index" />
    <Gen4Outline :enums="enums" channel="workspace_index" />
    <Contact />
  </div>
</template>
<script>
import * as StoryTemplateApi from '@/api/storytemplate'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as EvaluationApi from '@/api/evaluation'
import * as CultureApi from '@/api/culture'
import * as StoryCpApi from '@/api/storycp'
import * as StorySynesthesiaElementApi from '@/api/storysynesthesiaelement'
import * as StoryHotApi from '@/api/storyhot'
import { mapState, mapMutations} from 'vuex'
import StoryIdeaDialog from '@/components/StoryIdeaDialog'
import Gen4Outline from '@/components/Gen4Outline'
import Contact from '@/components/Contact'
export default {
  name: 'Article',
  components: {
    StoryIdeaDialog,Contact,Gen4Outline
  },
  data() {
    return {
      genTypeActive: '-1',
      fromType: 'writer_client',
      dialogShowGenbyoutline: false,
      showStateFlag: true,
      dialogShowGenbyidea: false,
      enums: {"ArticleTypeEnum":[{"desc":"","name":""}]},
    	targetPermit: 'article',
      queryForm: {
        name: '',
        fromType: 'writer-web',
        articleGenType: '-1',
        articleGenTypes: [],
        articleState: '',
        current: '',
        size: 20
      },
      activeMenuItem: 1,
      synesthesiaElements:[],
      cps:[],
      hots:[],
      data: [],
      storyTemplates:[],
      dongtai: [],
      tougao: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        // sizes: [20], // 每页行数 调整
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
    // this.handlePage()
    this.handleEnum()
    this.handleTemplatePage()
    // this.setStoryContents([])
    this.handleMessage()
  },
  computed: {
  	...mapState(['user', 'story', 'showDialogCdpFlag', 'showDialogDpFlag', 'showGenbyoutlineFlag']),
    isMobile() {
      return window.innerWidth <= 768 ? true : false;
    }
  },
  methods: {
    ...mapMutations(['setStory', 'setActiveMenuItem', 'setStoryContents', 'setShowGenbyoutlineFlag', 'setShowDialogCdpFlag', 'setShowDialogDpFlag', 'setShowChangpianDialogFlag', 'setShowLoginDialogFlag','setActiveTemplate']),
    contentChange(item){
      console.log('contentChange', item);
    },
    getTailByGenType(item){
      let tail = ''
      if(item.articleGenType == 'GEN_BY_ZHIHU' || item.articleGenType == 'GEN_BY_OUTLINE'){
        tail = '短篇小说'
      }else if(item.articleGenType == 'GEN_BY_IDEA'){
        tail = '超短篇小说'
      }else if(item.articleGenType == 'GEN_BY_CHANGPIAN'){
        tail = '长篇小说'
      }else{
        tail = ''
      }
      return tail;
    },
    handleTemplateClick(item) {
      this.setShowLoginDialogFlag(true)
    },
    handleTemplatePage(){
      StoryTemplateApi.page({
        templateType: 'GUANFANG',
        enableFlag: true,
        size: 6
      }).then(res => {
        let result = res.data.data;
        this.storyTemplates = result.records;
      });
    },
    handleGen(cmditem) {
      if(cmditem == 'chaishu'){
        this.setActiveMenuItem('2')
        this.$router.push('/bookbreakdown/index')
      }else{
        this.setShowLoginDialogFlag(true)
      }
    },
    newStoryByOutline() {
      if (!this.story.articleType || !this.story.articleType.trim()) {
          this.$message.error('请选择故事类型');
          return false;
      }

      if (!this.story.writingPerspective || !this.story.writingPerspective.trim()) {
          this.$message.error('请选择写作视角');
          return false;
      }
      // return false;
      ArticleApi.create(this.story).then(res => {
          if(res.data.code == 0){
            let data = res.data.data
            this.setStory(data)
            console.log('story', this.story);
            this.$router.push('/workspace/genbyoutline');
          }else{
            this.$message.error(res.data.msg);
          }
      })
    },
    handleRemove(item) {
      this.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ArticleApi.remove(item.id).then(res => {
          this.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    itemClick(item){
      this.setStory(item)
      setTimeout(()=>{
        if(!item.stepNumber || item.stepNumber == 0 || item.stepNumber == 1){
          if(this.story.articleGenType == 'GEN_BY_ZHIHU'){
              this.$router.push('/workspace/gen')
          }else if(this.story.articleGenType == 'GEN_BY_CHANGPIAN'){
            this.$router.push('/workspace/gencp')
          }else if(this.story.articleGenType == 'GEN_BY_IDEA'){
            this.$router.push('/workspace/genbyidea')
          }else if(this.story.articleGenType == 'GEN_BY_OUTLINE'){
            this.$router.push('/workspace/genbyoutline')
          }
        }else {
          if(this.story.articleGenType == 'GEN_BY_ZHIHU'){
            this.$router.push('/workspace/gen' + (item.stepNumber-1))
          }else if(this.story.articleGenType == 'GEN_BY_CHANGPIAN'){
            this.$router.push('/workspace/gencp')
          }else if(this.story.articleGenType == 'GEN_BY_IDEA'){
            this.$router.push('/workspace/genbyidea' + item.stepNumber)
          }else if(this.story.articleGenType == 'GEN_BY_OUTLINE'){
            this.$router.push('/workspace/genbyoutline' + item.stepNumber)
          }
        }
      }, 300)
    },
    handleEnum() {
      let that = this;
      EnumApi.list().then(res => {
        let result = res.data.data;
        result.ArticleStateEnum.unshift({name: '-1', desc: '全部'})
        result.ArticleGenTypeEnum.shift()
        result.ArticleGenTypeEnum.unshift({name: '-1', desc: '全部'})
        that.enums = result
      })
    },
    handlePage(current, item) {

      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size

      if(item){
        that.queryForm.articleGenType = item.name
        this.genTypeActive = item.name
      }
      if(this.queryForm.articleGenType == 'GEN_BY_CHANGPIAN'){
        that.showStateFlag = false
        that.queryForm.articleState = ''
      }else{
        that.showStateFlag = true
      }

      if(this.queryForm.articleGenType == 'GEN_BY_ZHIHU'){
        this.queryForm.articleGenTypes = ['GEN_BY_ZHIHU', 'GEN_BY_OUTLINE']
      }else{
        this.queryForm.articleGenTypes = []
      }

      if(!this.user || !this.user.id){
        return false;
      }
      that.queryForm.accountId = this.user.id

      let params = Object.assign({},that.queryForm)
      if(this.queryForm.articleState == '-1'){
        params.articleState = ''
      }
      if(this.queryForm.articleGenType == '-1'){
        params.articleGenType = ''
      }
      // 获取表格数据
      ArticleApi.page(params).then(res => {
        let result = res.data.data;
        that.data = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleMessage() {
      CultureApi.page({size: 100}).then(res => {
        let result = res.data.data;
        this.dongtai = result.records
      })
      EvaluationApi.page({size: 100}).then(res => {
        let result = res.data.data;
        this.tougao = result.records
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">
.layout {
  height: 100%;
}
.main {
  min-height: 1px;
  flex: 1;
}
.el-aside {
  padding: 0px;
  margin-bottom: 0 !important;
  height: 100%;
  width: 17rem !important;
}
.el-scrollbar {
  height: 100%;
  background-color: #ffffff;
}
.el-header {
  padding: 0px !important;
  background-color: #001A40;
}
.el-main {
  // padding: 0 !important;
  // margin: 0 !important;
  height: 100%;
  overflow: hidden; /* 禁止父容器滚动 */
}
.dialog-container {
  background: #F4F5F9;
  border-radius: 20px;
  height: calc( 100% - 1rem );
  margin: 0 1rem 1rem;
  margin-bottom: 1rem;
  padding:1rem;
  overflow: auto;
}
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
/deep/ .el-card {
    // border: 0;
    overflow-y: hidden !important;
}
/deep/ .el-empty__image img {
  margin-top: 20px;
}
.custom-select {
    /deep/ .el-input__inner {
      border-radius: 20px;
      height: 1.5rem;
      line-height: 1.5rem;
    }
    /deep/ .el-input__suffix {
      height: 1.5rem;
      line-height: 1.5rem;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
}

/deep/ .el-carousel__item {
  white-space: nowrap;
  overflow: hidden;        /* 隐藏超出元素盒子的内容 */
  text-overflow: ellipsis; /* 显示省略号(...) */
}
.search-title {
  /deep/ .el-input__inner {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 12px;
  }
  /deep/ .el-input__suffix {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}
.el-input__inner .el-input__inner:hover {
    /deep/ {
      // border-color: transparent; /* 边框颜色设置为透明 */
      // border: none;
      // outline: none;
    }
}
// .writer-home-card-box
.ly-story-gen-type:hover {
  color: #366EF4 !important;
}

.writer-home-card-box{
  // display: flex;
  // margin: 1rem 0 ;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}
.writer-home-card {
  flex: 1;
  cursor: pointer;
  // border: 1px solid red;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.05);
  border-radius: 14px;
  box-sizing: border-box; /* 边框计算在宽度内 */
  &-title-en {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255,255,255,.8);
    line-height: 22px;
    border-radius: 20px;
    display: inline-block;
    padding: .1rem 1rem;
  }
  &-title {
    font-weight: 600;
    font-size: 28px;
    color: rgba(0,0,0,0.9);
    margin: 1rem 0;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    &-img {
      width: 7rem;
      margin-top:  -.7rem;
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    &-img {
      width: 1rem;
    }
  }
  &-up {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.4);
    line-height: 22px;
    display: flex;
    align-items: flex-start; /* 顶部对齐 */
    &-icon {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: #2BA471;
      line-height: 1rem;
      margin-right: .5rem;
      border-radius: 3px;
      padding: .25rem .5rem;
      &-img {
        width: 1rem;
      }
    }
  }
}
.writer-home-card-box > :nth-child(1) {
  background-image: url('../assets/writer-edit2.png'), linear-gradient( 151deg, rgba(125,174,255,0.6) 0%, rgba(148,255,197,0.3) 50%, rgba(172,195,255,0.1) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  // background-image: ;
}
.writer-home-card-box > :nth-child(2) {
  background-image: url('../assets/writer-light2.png'), linear-gradient( 137deg, rgba(137,150,255,0.6) 0%, rgba(235,210,255,0.6) 45%, rgba(144,126,255,0.04) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}
.writer-home-card-box > :nth-child(3) {
  background-image: url('../assets/writer-changpian.png'), linear-gradient( 314deg, #FFECA8 0%, #FFFEDE 50%, #FFD4AC 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}
.word-count-tail {
  border-radius: 6px 0px 20px 0px;
  width: auto; /* 默认值，但明确设置有助于理解 */
  white-space: nowrap; /* 防止文本换行 */
  display: inline-block;
  padding: .25rem 1.5rem .25rem 1rem;
  // font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  &-cdp{
    background: linear-gradient( 90deg, #DAE1FF 0%, #F2F3FE 100%);
    color: #0052D9;
  }
  &-dp{
    background: linear-gradient( 90deg, #DAE1FF 0%, #F6F2FE 100%);
    color: #4043FF;
  }
  &-cp{
    background: linear-gradient( 90deg, #FFE9BC 0%, #FFF7DC 100%);
    color: #FD733B;
  }
}
.data-empty {
  background: #FFFFFF;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
  border-radius: 0px 20px 20px 20px;
  padding: 1rem;
}
/deep/ .el-empty {
  // min-height: 30vh !important;
  // height: 50vh;
}
/deep/ .el-empty__bottom {
  margin-top: 10px;
}

.writer-story-card-box {
  width: 100%;
  min-height: 400px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
  // background: #F4F5F9;
  border-radius: 0px 20px 20px 20px;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  grid-auto-rows: 10px;
  margin-bottom: 10px;
}

/deep/ .el-card.is-always-shadow {
  box-shadow: none !important;
}
/deep/ .el-card.is-always-shadow:hover {
  box-shadow: 4px 4px 15px 0 rgba(54, 110, 244, .2) !important;
}
.writer-story-card:hover{
  box-shadow: 4px 4px 15px 0 rgba(54, 110, 244, .2) !important;
}
.writer-story-card {
  cursor: pointer;
  width: 100%;
  box-shadow: none;
  max-width: 400px;
  box-sizing: border-box; /* 边框计算在宽度内 */
  border-radius: 6px;
  grid-row-end: span 7;
  &-title {
    font-weight: 600;
    font-size: 1rem;
    color: rgba(0,0,0,0.9);
    height: 3rem;
    line-height: 1.5rem;
  }
  &-time {
    // font-weight: 400;
    font-size: 12px;
    color: rgba(0,0,0,0.3);
    line-height: 22px;
    &-label {
      margin-right: .5rem;
    }
  }
  &-menu {
    &-delete {
      // padding: .5rem;
      img {
        margin-right: .5rem;
      }
      // background: #F2F3FF;
      border-radius: 3px;
      color: #0052D9;
      font-size: .8rem;
    }
  }
}
/* 假设你的菜单类名为 .my-menu */
/deep/ .el-menu-agent .el-menu-item {
  border-radius: 6px !important;
  margin: 0 1rem 6px;
  height: 2.5rem;
  line-height: 2.5rem;
}
/deep/ .el-menu-agent .el-menu-item:hover {
  background-color: #4B4B4B !important;
}
.el-menu-agent .el-menu-item.is-active {
  background-color: #0252D9 !important; /* 激活状态下的背景颜色 */
}
/deep/ .writer-story-card-menu-delete .el-dropdown-item {
  background-color: transparent !important;
}
/deep/ .writer-story-card-menu-delete .el-dropdown-item:hover {
  background-color: transparent !important;
}

@media screen and (min-width: 500px) {
  .writer-home-card-box {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
  /deep/ .custom-dialog .el-dialog {
    width: 60%;
  }
}
.news-box {
  display: flex;
  margin-bottom: 1rem;
  &-pc{
    justify-content: space-between;
  }
  &-mobile{
    flex-direction: column;
  }
  &-last {
    background: #FFFFFF;
    border-radius: 50px 50px 50px 50px;
    box-shadow: 2px 2px 10px 0px rgba(54,110,244,0.07);
    display: flex;
    align-items: center;
    padding: 0 1rem 0 .25rem;
    &-pc{
      flex: 1;
      margin-right: 1rem;
    }
    &-mobile{
      margin-bottom: 1rem;
    }
    &-title {
      color: #366EF4;
      font-weight: 600;
      line-height: 24px;
      font-size: 16px;
      margin-left: 10px;
    }
    &-content {
      margin-left: 1rem;
      color: #242424;
      flex: 1;
      font-size: 14px;
    }
  }
  &-contribute {
    background: #FFFFFF;
    border-radius: 50px 50px 50px 50px;
    box-shadow: 2px 2px 10px 0px rgba(54,110,244,0.07);
    display: flex;
    align-items: center;
    padding: 0 1rem 0 .25rem;
    &-title {
      color: #366EF4;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-wrap: nowrap;
      margin-left: 10px;
    }
    &-content {
      margin-left: 1rem;
      color: #366EF4;
      font-size: 14px;
    }
  }
}

// mly
.mly-container {
  display: flex; /* 使用Flexbox布局 */
  flex-wrap: wrap; /* 允许元素换行 */
  gap: 16px; /* 设置元素之间的间距 */
}
.mly-hr-dotted2 {
    border: 0;
    // border-top: 2px dotted #a2a9b6;
    border-top: .5px solid rgba(0,0,0,0.2);
}
/* 默认样式（大屏） */
.mly-box {
  flex: 1 1 auto; /* 每个元素占据等宽 */
  // max-width: 420px; /* 设置最小宽度 */
  min-width: 300px;
  display: flex; /* 启用Flexbox布局 */
  flex-direction: column; /* 子元素垂直排列 */
  gap: 16px;
}
.mly-box-x {
  flex: 1 1 auto; /* 每个元素占据等宽 */
  min-width: 300px;
  // max-width: 420px;
  display: flex; /* 启用Flexbox布局 */
  flex-direction: row; /* 子元素垂直排列 */
  gap: 16px;
}
.mly-box-y {
  flex: 1; /* 每个元素占据等宽 */
  // min-width: 180px; /* 设置最小宽度 */
  display: flex; /* 启用Flexbox布局 */
  flex-direction: column; /* 子元素垂直排列 */
  gap: 16px;
}
.mly-half {
  flex: 1; /* 子元素平分空间 */
  padding: 25px 32px;
  box-shadow: 2px 2px 10px 0px rgba(54,110,244,0.07);
}
.mly-half:hover{
  cursor: pointer;
  box-shadow: 4px 4px 15px 0 rgba(54, 110, 244, .2);
}
.mly-full {
  flex: 1; /* 子元素平分空间 */
  padding: 1rem 1rem;
}

.muban-box {

  &-item {
    padding: 1rem 1rem;
    background: #FFFFFF;
    overflow: hidden;
    border-radius: 20px;
    height: 100%;
    &-content {
      text-align: justify;
      font-size: 12px;
      color: rgba(0,0,0,0.8);
      line-height: 22px;

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: wrap;
    }
    &-tag {
      padding: 5px 0;
      &-item{
        margin-top: .5rem;
        display: inline-block;
        background: linear-gradient( 270deg, #ADB3FF 0%, #8489FA 100%);
        border-radius: 20px;
        font-size: 12px;
        color: #FFFFFF;
        padding: .25rem .5rem;
        margin-right: .5rem;
        white-space: nowrap;
      }
    }
  }
}
.mly-border-radius-30 {
  border-radius: 30px;
}
.mly-border-radius-20 {
  border-radius: 20px;
}
.mly-title {
  font-weight: 600;
  font-size: 20px;
  color: rgba(0,0,0,0.8);
  white-space: nowrap;
}
.mly-subtitle {
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 22px;
  padding: 2px 10px;
  margin: 10px 0;
  display: inline-flex;
  white-space: nowrap;
}
.mly-shangsheng {
  background: linear-gradient( 45deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.8) 100%);
  border-radius: 20px 20px 20px 20px;
  padding: 1px 8px;
  font-size: 12px;
  align-items: center;
}
.mly-detail {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0,0,0,0.8);
  line-height: 22px;
  align-items: center;
  overflow: hidden;
  &-text{
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
    padding-left: 10px;
    overflow: hidden;
  }
}
/* 小屏下的样式 */
@media (max-width: 768px) {
  .mly-box, .mly-box-x {
    flex-basis: 100%; /* 每个元素占满整个屏幕宽度 */
    min-width: 100%;
  }
  .mly-hide {
    display: none !important;
  }
}
// mly end
</style>

<style>
.custom-carousel {
  position: relative;
  width: 100%;
}

.custom-carousel .el-carousel__indicators--vertical {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-carousel .el-carousel__indicator--vertical {
  margin: 5px 0;
}

.custom-carousel .el-carousel__indicator button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .6);
  border: 1px solid rgba(255, 255, 255, .6);
  opacity: 0.5;
}

.custom-carousel .el-carousel__indicator--vertical.is-active button {
  background-color: #647EFF;
  border-color: #647EFF;
  opacity: 1;
}

</style>
