var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"llm"},[_c('el-button',{on:{"click":function($event){_vm.showModal = true}}},[_vm._v("打开模态窗口")]),_c('CustomModal',{attrs:{"visible":_vm.showModal,"title":"自定义模态窗口"},on:{"update:visible":function($event){_vm.showModal = $event},"confirm":_vm.handleConfirm}},[_c('p',[_vm._v("这里是模态窗口的内容。")])]),_vm._m(0),_c('div',{domProps:{"innerHTML":_vm._s(_vm.msg)}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.llmstream}},[_vm._v("sse")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.log}},[_vm._v("log")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.message}},[_vm._v("message")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sendTips}},[_vm._v("sendTips")]),(false)?_c('div',{staticClass:"table-container1"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e(),_vm._m(9),_c('div',{staticStyle:{"position":"relative","width":"200px","height":"200px"}}),_c('LlmSkeleton')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"stretch"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容11")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容12")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容13")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-cell1"},[_c('div',{staticClass:"content"},[_vm._v("内容14")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animation-container"},[_c('img',{staticClass:"animation-frame",attrs:{"src":"","alt":"Animation Frame"}})])
}]

export { render, staticRenderFns }