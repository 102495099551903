<template>
<div style="padding: 1rem 1rem;position: relative;">
  <div style="font-weight: 400;font-size: 20px;color: rgba(0,0,0,0.8);" class="d-flex flex-y-center">
      小说拆书
      <el-tooltip style="margin-left: 6px;" popper-class="custom-tooltip" effect="dark" content="本功能旨在通过拆解作品，帮助创作者更深入的学习优秀作品，提升自己的创作能力。我们坚决支持原创，保护原创，反对抄袭。" placement="right">
        <!-- <i class="el-icon-info"></i> -->
        <img src="../../assets/icon-info.png" style="width: 20px;" />
      </el-tooltip>
  </div>
  <el-button icon="el-icon-circle-plus-outline" style="border-radius: 6px !important;padding: 0.5rem 2rem;margin-top: 1rem;margin-bottom: .5rem;" size="small" type="primary" @click="token!=''?setShowBreakdownDialogFlag(true):setShowLoginDialogFlag(true)">新建拆书</el-button>
  <!-- 数据表格 -->
  <el-table
    :row-style="{height:'48px'}"
    :cell-style="{padding:'14px 0px'}"
    :header-cell-style="{background:'#FAFAFD',color:'rgba(0,0,0,0.4)','font-weight': 400, 'font-size': '14px'}"
    :data="tableData"
    :highlight-current-row="false"
    ref="tableContainer"
    class="table-container">
    <el-table-column
      prop="name"
      label="作品名称"
      class="col-name"
      min-width="200px">
      <template slot-scope="scope">
        {{scope.row.name?scope.row.name.split('.')[0]:''}}
      </template>
    </el-table-column>

    <el-table-column
      prop="createTime"
      label="上传时间"
      min-width="100px"/>

    <el-table-column
      prop="payState"
      label="拆书进度"
      min-width="100px">
      <template slot-scope="scope">
        <div v-if="scope.row.breakdownState == 'SUBMITTED'">
          <el-progress :text-inside="true" :stroke-width="16" :percentage="scope.row.progress" text-color="#ffffff"></el-progress>
        </div>
        <div class="d-flex flex-y-center" v-if="scope.row.breakdownState == 'COMPLETED'" style="color: #0052D9;">
          完成
        </div>
        <div class="d-flex flex-y-center" v-if="scope.row.breakdownState == 'FAILED'" style="color: #F43636;">
          失败<el-tooltip style="display: inline-block;" effect="dark" :content="scope.row.breakdownFailedReason" placement="right">
            <img src="../../assets/icon-info-red.png" style="width: 14px;height: 14px;" />
          </el-tooltip>
        </div>
      </template>
    </el-table-column>

    <el-table-column min-width="60px" label="操作" align="center">
      <template slot="header">
      </template>
      <template slot-scope="scope">
        <el-link :underline="false" v-if="scope.row.breakdownState != 'COMPLETED'" type="info" disabled>查看</el-link>
        <el-link :underline="false" v-else @click.native="rowClick(scope.row)" type="primary" >查看</el-link>
      </template>
    </el-table-column>

    <!-- empty -->
    <template v-slot:empty v-if="token == ''">
      <div class="data-empty">
        <img src="https://www.creatimix.com/static/empty.jpg" style="height: 80px;">
        <p>马上登录开始创作之旅!</p>
        <p style="margin: 0;color: rgba(0,0,0,0.6);font-size: 14px;">
          <el-button style="border-radius: 6px !important;padding: 0.5rem 2rem;margin-top: 1rem;margin-bottom: .5rem;" size="small" type="primary"  @click="setShowLoginDialogFlag(true)">注册/登录</el-button>
        </p>
      </div>
    </template>
  </el-table>

  <el-row class="pagination-fixed"  v-if="token != ''" :style="{ 'width': tableWidth + 'px'}">
    <el-col>
      <div class="pagination">
        <el-pagination background
          hide-on-single-page
          :page-sizes="paginations.pages"
          :page-size="paginations.size"
          :layout="paginations.layout"
          :total="paginations.total"
          :current-page.sync="paginations.current"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </el-col>
  </el-row>
  <BookBreakdownDialog :enums="enums" channel="bookbreakdown_index" />
  <BookBreakdownDetailDialog v-if="showBreakdownDetailDialogFlag" :enums="enums" :uploadFile="uploadFile" />
</div>
</template>
<script>
import * as EnumApi from '@/api/enums'
import * as FileApi from '@/api/file'
import BookBreakdownDialog from '@/components/BookBreakdownDialog/index'
import BookBreakdownDetailDialog from '@/components/BookBreakdownDialog/detail'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    BookBreakdownDialog,BookBreakdownDetailDialog
  },
  data() {
    return {
      tableWidth: 0,
      sliderBoxActive: 0,
      fromType: 'writer_client',
      enums: {},
      intervalId: null,
      uploadFile: {},
    	targetPermit: 'article',
      queryForm: {
        targetType: 'BOOK_BREAKDOWN',
        fromType: 'writer-web',
        current: '',
        size: 20
      },
      tableData: [],
      progressMap: new Map(),
      activeMenuItem: 1,
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        // sizes: [20], // 每页行数 调整
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableWidth = this.$refs.tableContainer.$el.offsetWidth;
      console.log('tableWidth', this.tableWidth);
    })
    this.setActiveMenuItem('2')
    this.handleEnum()
    this.handlePage()

    // 清除定时器，停止周期性执行
    if (this.intervalId) {
      // 清除定时器，停止周期性执行
      clearInterval(this.intervalId);
    }
    //refrush
    this.intervalId = setInterval(() => {
      // console.log('tableData:', this.tableData);
      let items = this.tableData.filter((item) => {return item.breakdownState == "SUBMITTED"})
      // console.log('items:', items);
      if(items.length > 0){
        this.handlePage()
      }
    }, 1000);
  },
  computed: {
  	...mapState(['user', 'story', 'token', 'showBreakdownDetailDialogFlag']),
    isMobile() {
      return window.innerWidth <= 768 ? true : false;
    }
  },
  beforeDestroy() {
    // 清除定时器，停止周期性执行
    if (this.intervalId) {
      // 清除定时器，停止周期性执行
      clearInterval(this.intervalId);
    }
  },
  methods: {
    ...mapMutations(['setStory', 'setShowBreakdownDialogFlag', 'setShowLoginDialogFlag', 'setShowBreakdownDetailDialogFlag', 'setActiveMenuItem']),
    handleRemove(item) {
      this.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'custom-confirm',
        center: true
      }).then(() => {
        FileApi.remove(item.id).then(res => {
          this.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleProgress(data){
      let diff = (new Date().getTime() - new Date(data.createTime).getTime())/1000
      // let progress = Number(diff * this.getRandomInt(1,5)).toFixed(0)
      let progress = this.getRandomInt(1,20)
      // console.log('diff', diff, 'progress', progress)
      let pre = this.progressMap.get(data.id)
      if(pre){
        progress += pre.progress
        this.progressMap.set(data.id, {'progress': progress})
      }else{
        this.progressMap.set(data.id, {'progress': progress})
      }
      if(progress >= 100){
        progress = 97
      }
      data.progress = progress
    },
    getRandomInt(min, max) {
      min = Math.ceil(min); // 向上取整
      max = Math.floor(max); // 向下取整
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    handleEnum() {
      let that = this;
      EnumApi.list().then(res => {
        let result = res.data.data;
        that.enums = result
      })
    },
    rowClick(item) {
      this.uploadFile = item
      this.setShowBreakdownDetailDialogFlag(true)
    },
    handlePage(current) {
      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size
      // console.log('workspace index user', this.user);
      if(!this.user || !this.user.id){
        return false;
      }
      that.queryForm.accountId = this.user.id
      // 获取表格数据
      FileApi.page(that.queryForm).then(res => {
        let result = res.data.data;

        that.tableData = result.records
        that.tableData.forEach((item,index) => {
          that.handleProgress(item)
        })
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-tooltip__popper {
  max-width: 200px;
}
.pagination-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 1rem;
  margin-right: 3rem;
  background: #FAFAFD;
  padding: 1rem 0;
}
/* 取消行悬停效果 */
/deep/ .el-table--enable-row-hover {
    background-color: transparent !important;
}

/* 取消行过渡效果 */
/deep/ .el-table--enable-row-transition {
    transition: none !important;
}
/deep/ .el-table th.el-table__cell.is-leaf, /deep/ .el-table td.el-table__cell {
  border-bottom: none;
}
.table-container {
  margin-bottom: 1rem;
  /deep/ .el-table__body {
    border-spacing: 0 1rem;
    border-radius: 20px;
    width: auto !important;
  }
  /deep/ .el-table__body-wrapper.is-scrolling-left{
    width: auto !important;
  }
  /deep/ .el-table__body-wrapper .el-table__empty-block {
    width: auto !important;
  }
}

::v-deep .el-table td:first-child {
  border-radius: 20px 0 0 20px;
  padding-left: 2rem;
}
::v-deep .el-table td:last-child {
  border-radius: 0 20px 20px 0;
}

::v-deep td.el-table__cell {
  color: rgba(0,0,0,0.8);
  background-color: transparent !important;
}
::v-deep .el-table .el-table__cell:first-child {
  font-weight: bold;
  padding-left: 2rem;
}
::v-deep .el-table__row:hover{
  background-color: #E5E7FC !important;
}
::v-deep .el-table__row {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.8);
  background: #F1F1F5;
}

/deep/ .el-message-box__wrapper .el-message-box {
  border-radius: 20px !important;
  padding: .5rem;
  padding-bottom: 1rem;
}
.data-empty {
  padding: 1rem;
  height: calc( 100vh - 8rem );
}
/deep/ .el-empty {

}
/deep/ .el-empty__bottom {
  margin-top: 10px;
}
/deep/ .el-table__empty-text {
  line-height: 0px !important;
}
</style>
