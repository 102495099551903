<template>

    <!-- 从创意开始 弹窗 -->
    <el-dialog
      class="custom-dialog custom-dialog-position new-dp-template-dialog"
      ref="dialog"
      top="10vh"
      :visible.sync="showDialogDpFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="true"
      :destroy-on-close="true"
      :show-close="true"
      :close-on-click-modal="true"
      :wrapperClosable="true"
      @close="close"
      >
      <div style="padding: 0 1rem 1rem 0;position: relative;">
        <el-row :gutter="10" ref="storyTemplate">
          <!-- 模板 -->
          <el-col :span="24" style="margin-top: 1rem;">
            <label for="tags" class="model-box-item-title">全部模版</label>
          </el-col>

          <el-col :span="24" style="margin-top: 1rem;">
            <div class="d-flex flex-y-center">
              <!-- <span style="margin-right: .5rem;">模板类型</span> -->
              <el-select v-if="false" class="custom-select-v1"
                  v-model="queryForm.templateType"
                  filterable
                  style="width: 5rem;margin-right: 1rem;"
                  size="mini"
                  placeholder="模板类型">
                <el-option
                  v-for="(item,index) in enums.TemplateTypeEnum"
                  :label="item.desc"
                  :key="index"
                  :value="item.name"
                />
              </el-select>
              <!-- <span style="margin-right: .5rem;">模板标签</span> -->
              <el-select @change="handleTemplatePage" v-model="tags" multiple placeholder="筛选" class="custom-select-v1">
                  <el-option-group
                    v-for="group in storyTemplateTags"
                    :key="group.label"
                    :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label">
                    </el-option>
                  </el-option-group>
                </el-select>
            </div>
          </el-col>
          <!-- 模板区域 -->
          <el-col :span="24" style="overflow: hidden;">
            <div class="model-box">
                <div v-if="storyTemplates.length > 0" @click.stop="handleTemplateClick(item)" class="model-box-item" :class="{'model-box-item-active': item.id == activeItem.id}" :key="index" v-for="(item,index) in storyTemplates">
                  <template v-if="index == 0">
                    <div class="model-box-item-not flex-center-x" style="height: 100%;">
                      <img src="../../assets/not-template.png" alt="" style="width: 73px;height: 73px;"/>
                      <div class="model-box-item-title" style="margin: .5rem 0;">
                        {{item.title}}
                      </div>
                      <div class="model-box-item-content ellipsis4" style="margin: 0;">
                        {{item.content}}
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="model-box-item-title">
                      {{item.title}}
                    </div>

                    <el-tooltip placement="top" class="ly-tooltip-class">
                      <div slot="content">
                        <div class="score-box d-flex" style="padding: .5rem  .5rem 0 .5rem;align-items: center;">
                          <div class="score-info d-flex" style="flex-direction: column;margin-right: 1rem;">
                            <div class="score-text" style="font-weight: 400;font-size: 12px;color: #FFFFFF;">
                              模板难度
                            </div>
                            <div class="score-number" style="font-weight: 600;font-size: 32px;color: rgba(255,255,255,0.9);">
                              {{Number(item.score).toFixed(1)}}
                            </div>
                          </div>
                          <div class="score-star">
                            <div class="model-box-item-score d-flex" style="">
                              <div v-for="number in 5" :key="number" >
                                <img style="width: 16px; height: 16px;" v-if="number <= item.score/2" src="../../assets/star-yellow.png" alt="" />
                                <img style="width: 16px; height: 16px;" v-else-if="number == (item.score/2+0.5)" src="../../assets/star-half.png" alt="" />
                                <img style="width: 16px; height: 16px;" v-else src="../../assets/star-white.png" alt="" />
                              </div>
                            </div>
                            <div class="score-desc" style="font-size: 12px;line-height: 32px;color: #FFFFFF;">
                              {{item.scoreDesc}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="model-box-item-score d-flex" style="margin: .6rem 0;">
                        <div v-for="number in 5" :key="number" >
                          <img style="width: 16px; height: 16px;" v-if="number <= item.score/2" src="../../assets/star-yellow.png" alt="" />
                          <img style="width: 16px; height: 16px;" v-else-if="number == (item.score/2+0.5)" src="../../assets/star-half.png" alt="" />
                          <img style="width: 16px; height: 16px;" v-else src="../../assets/star-white.png" alt="" />
                        </div>
                      </div>
                    </el-tooltip>


                    <el-tooltip placement="top">
                      <div slot="content" style="">
                        <div style="padding: .5rem;width: 13rem;text-align: justify;line-height: 1.5rem;" class="d-flex">
                          {{item.content}}
                        </div>
                      </div>
                      <div class="model-box-item-content ellipsis4">
                        {{item.content}}
                      </div>
                    </el-tooltip>
                    <div class="model-box-item-tag">
                      <span class="model-box-item-tag-item" :key="indexTag" v-for="(itemTag,indexTag) in item.tag&&item.tag.split(',')">{{itemTag}}</span>
                    </div>
                  </template>
                </div>
                <el-empty  v-if="storyTemplates.length==0" image="https://www.creatimix.com/static/empty.jpg" description="没有相关模板" style="margin: 0 auto;background-color: #FFFFFF;">
                  <!-- <p style="margin: 0;color: rgba(0,0,0,0.6);font-size: 14px;">敬请期待!</p> -->
                </el-empty>
            </div>
          </el-col>
        </el-row>

        <!-- 分页 -->
        <el-row>
          <el-col>

            <div style="display: flex;justify-content: space-between;margin-top: 1rem;">
              <div class="pagination">
                <el-pagination background
                  :page-sizes="paginations.pages"
                  :page-size="paginations.size"
                  :layout="paginations.layout"
                  :total="paginations.total"
                  :current-page.sync="paginations.current"
                  @current-change="handleCurrentChange"
                  @size-change="handleSizeChange"
                />
              </div>
              <el-button style="padding: 10px 55px;" :disabled="activeItem.id == '-1'" type="primary" @click="handleOk">确认选中</el-button>
            </div>
          </el-col>
        </el-row>
      </div>

    </el-dialog>
</template>
<script>
import * as StoryCpApi from '@/api/storycp'
import * as StoryTemplateApi from '@/api/storytemplate'
import * as EnumApi from '@/api/enums'
import * as StorySynesthesiaElementApi from '@/api/storysynesthesiaelement'
import * as StoryHotApi from '@/api/storyhot'
import { mapState, mapMutations} from 'vuex'
import * as ArticleApi from '@/api/article'
import { nextTick } from 'vue'
export default {
  name: 'StoryByIdea',
  props: {
    enums: {
      type: Object,
      default: {}
    },
    channel: {
      type: String,
      default: 'workspace_index'
    },
  },
  components: {

  },
  data() {
    return {
      activeItem: {id: '-1'},
      synesthesiaElements:[],
      cps:[],
      hots:[],
      storyTemplates:[],
      storyTemplateTags:[],
      tags: [],
      queryForm: {
        name: '',
        templateType: 'GUANFANG',
        enableFlag: true,
        tag: '',
        current: '',
        size: 100
      },
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        // sizes: [20], // 每页行数 调整
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  mounted() {
    console.log('mounted');
    this.activeItem =  {id: '-1'}
    this.handleTemplatePage()
    this.handleTemplateTag()
  },
  computed: {
    ...mapState(['user', 'story', 'showDialogDpFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : window.innerWidth <= 1200 ? '80%' : '60%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "4vh" : '10vh';
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogDpFlag', 'setActiveTemplate']),
    close(){
      this.setShowDialogDpFlag(false);
    },
    handleOk(){
      console.log('this.$parent', this.$parent);
      this.$parent.activeItem = this.activeItem
      this.setActiveTemplate(this.activeItem)
      this.$parent.createOrUpdateStroy(4);
      this.story.storyTemplateId = this.activeItem.id
      this.setStory(this.story)
      nextTick(()=>{
        this.close()
      })
    },
    handleTemplateClick(item) {
      this.activeItem = item
    },
    handleTemplatePage(){
      console.log('this.queryForm', this.queryForm);
      this.queryForm.tag = this.tags.join(',')
      // this.queryForm.tags = []
      StoryTemplateApi.page(this.queryForm).then(res => {
        let result = res.data.data;
        this.storyTemplates = result.records;
        // 不是用模板占位符
        const notTemplate = {
          id: '0',
          title: '自由模式',
          tag: '',
          content: '融合套路，灵活创作',
        }
        this.storyTemplates.unshift(notTemplate)
        if(this.storyTemplates.length > 1){
          if(window.innerWidth <= 768){
            document.documentElement.style.setProperty('--template-rows', '2');
          }else{
            document.documentElement.style.setProperty('--template-rows', '4');
          }

        }
        //default
        this.storyTemplates.forEach((item,index) => {
          if(this.story.storyTemplateId == item.id){
            this.activeItem = item
            return
          }
        })
        this.paginations.total = Number(result.total)
        this.paginations.current = Number(result.current)
        this.paginations.size = Number(result.size)
      });
    },
    handleTemplateTag() {
      EnumApi.templateTags().then(res => {
        let result = res.data.data;
        let tag = result.tag[0]

        //type tag
        let typeTagOptions = []
        tag.typeTag.split(',').forEach((item, index) => {
          typeTagOptions.push({
            value: 'type' + index,
            label: item
          })
        })
        let typeTag = {
          label: '类型',
          options: typeTagOptions,
          }
        this.storyTemplateTags.push(typeTag)

        // role tag
        let roleTagOptions = []
        tag.roleTag.split(',').forEach((item, index) => {
          roleTagOptions.push({
            value: 'role' + index,
            label: item
          })
        })
        let roleTag = {
          label: '角色',
          options: roleTagOptions,
          }
        this.storyTemplateTags.push(roleTag)

        // age tag
        let ageTagOptions = []
        tag.ageTag.split(',').forEach((item, index) => {
          ageTagOptions.push({
            value: 'age' + index,
            label: item
          })
        })
        let ageTag = {
          label: '时代',
          options: ageTagOptions,
          }
        this.storyTemplateTags.push(ageTag)

        // plotTag tag
        let plotTagOptions = []
        tag.plotTag.split(',').forEach((item, index) => {
          plotTagOptions.push({
            value: 'plot' + index,
            label: item
          })
        })
        let plotTag = {
          label: '情节',
          options: plotTagOptions,
          }
        this.storyTemplateTags.push(plotTag)

        // emotionTag tag
        let emotionTagOptions = []
        tag.emotionTag.split(',').forEach((item, index) => {
          emotionTagOptions.push({
            value: 'emotion' + index,
            label: item
          })
        })
        let emotionTag = {
          label: '情绪',
          options: emotionTagOptions,
          }
        this.storyTemplateTags.push(emotionTag)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  },
  beforeDestroy() {

  },
}
</script>

<style scoped lang="scss">
:root {
  --template-rows: 4; /* 默认红色 */
}
$tagMT: .5rem;
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body > div:first-of-type {
  padding: 0 0 .5rem 0 !important;
}

/deep/ .story-idea .el-textarea__inner{
  background: #F4F4F4;
  padding: 1rem;
}
.pagination-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 1rem;
  margin-right: 3rem;
  background: #FAFAFD;
  padding: 1rem 0;
}
.story-idea-tag-box {
  margin-top: $tagMT;
  &-tag {
    display: inline-block;
    background: #F2F3FF;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #242424;
    padding: .25rem .5rem;
    margin: 0 .5rem .5rem 0;
    white-space: nowrap;
  }
}
.get-more {
  font-size: 12px;
  color: #0052D9;
  white-space: nowrap;
  margin-top: $tagMT;
}
/* 全局滚动条轨道的padding */
.model-box::-webkit-scrollbar-track {
  background-color: transparent;
  // padding: 20px 0; /* 上下padding */
  margin: 1rem 0;
}
/* 滚动条的样式 */
.model-box::-webkit-scrollbar {
  height: 10px;
  margin: 1rem 0;
  background-color: transparent;
}
.model-box::-webkit-scrollbar-thumb {
  background-color: rgba(54,110,244,0.1);
  // border-radius: 6px;
  // background-color: transparent;
}
.model-box {
  display: grid;
  max-height: 70vh;
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  // grid-auto-rows: minmax(30px, auto); /* 行的高度至少为100px，但可以更高以适应内容 */
  // grid-auto-flow: row;
  grid-template-columns: repeat(var(--template-rows), 1fr);
  /*  声明了两行，行高分别为 50px 50px  */
  // grid-template-rows:  repeat(var(--template-rows), 1fr);
  gap: .5rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  overflow-x: auto;
  // overflow-y: hidden;
  white-space: nowrap; /* 确保内容不会换行 */
  box-sizing: border-box; /* 边框计算在宽度内 */

  &-item:hover{
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    background: linear-gradient( 270deg, #F3F4FF 0%, #E8EAFF 100%);
    cursor: pointer;
    // border: 1px solid #366EF4;
  }
  &-item {
    position: relative;
    width: 12rem;
    padding: 1rem;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
    border-radius: 14px;
    box-sizing: border-box; /* 边框计算在宽度内 */
    border: 1px solid #EBEEF5;
    background: #FFFFFF;
    overflow: hidden;
    color: #303133;
    &-title {
      font-weight: 600;
      font-size: 1rem;
      color: rgba(0,0,0,0.9);
    }
    &-active {
      border: 1px solid #366EF4;
      background: linear-gradient( 270deg, #F3F4FF 0%, #E8EAFF 100%);
    }
    &-content {
      font-size: 12px;
      color: rgba(0,0,0,0.6);
      line-height: 1.25rem;
      text-align: justify;
      // margin-top: 1rem;
      margin-bottom: 2rem;
    }
    &-tag {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 1rem;
      &-item{
        margin-top: .5rem;
        display: inline-block;
        background: #F2F3FF;
        border-radius: 12px;
        font-weight: 400;
        font-size: 12px;
        color: #366EF4;
        padding: .25rem .5rem;
        margin-right: .5rem;
        white-space: nowrap;
      }
    }
  }
}
.multiline-ellipsis {
  position: relative; /* 为伪元素定位做准备 */
  max-height: 6em; /* 根据内容和行高设置最大高度，这里假设每行2em */
  overflow: hidden; /* 隐藏溢出的内容 */
  line-height: 1.25em; /* 设置行高，与最大高度保持一致 */
  text-align: justify; /* 文本两端对齐 */
  // padding-right: 20px; /* 为省略号留出空间 */
}

.multiline-ellipsis::after {
  content: '...';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  color: #FFFFFF;
  padding-left: 40px; /* 渐变背景的长度 */
  background: linear-gradient(to right, transparent, white 50%);
  /* 渐变背景从透明到白色，覆盖超出的文本 */
}
.ellipsis4 {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.custom-select-v1 {
  /deep/ .el-input__inner {
    border-radius: 20px;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    background: #FFFFFF;
    border: 1px solid #EBEEF5 !important;
  }
  /deep/ .el-input__suffix {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}
.field-content {
  color: rgba(0,0,0,0.6);
}
</style>
