<template>
  <div class="">
    <div class="llm-skeleton-tips d-flex" style="padding: 1rem 0;">
      {{ message }}
      <div class="loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
    <el-skeleton :rows="10" animated />
  </div>
</template>
<script>
import { EventBus } from '@/utils/eventbus.js';
export default {
  name: 'LlmSkeleton',
  props: {

  },
  data() {
    return {
      message: ''
    }
  },
  computed: {

  },
  created() {
    EventBus.$on('llm-tips', (msg) => {
      // console.log('eventbus data=', msg);
      this.message = msg;
    });
  },
  beforeDestroy() {
    EventBus.$off('llm-tips');
  },
  methods: {

  }
}
</script>
<style>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #333;
  margin: 0 5px;
  animation: loading 1s infinite ease-in-out alternate;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading {
  from {
    transform: scale(1);
    opacity: 0.2;
  }
  to {
    transform: scale(1.5);
    opacity: 1;
  }
}
</style>
