<!-- CustomModal.vue -->
<template>
  <transition name="modal">
    <div v-if="visible" class="modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="close" @click="closeModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer d-flex flex-between">
            <div @click.stop="close" style="text-align: center;cursor: pointer;">
              <div class="invite-button" @click.stop="close" style="background: linear-gradient( 270deg, #0052D9 0%, #00FFC2 100%);
              border-radius: 20px;padding: .5rem 4rem;">
                       Okay!已了解
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    title: String
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
    },
    confirm() {
      this.$emit('confirm');
      this.closeModal();
    }
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-dialog {
  width: 50%;
}

.modal-content {
  background: #fff;
  border-radius: 3px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
}

.modal-header, .modal-footer {
  /* padding: 15px; */
  /* border-bottom: 1px solid #e5e5e5; */
}
/* 鼠标悬停时的样式 */
.invite-button:hover {
  opacity: 0.8; /* 按钮本身可以稍微透明一些，以便蒙版更明显 */
}

.invite-button::after {
  /* 蒙版层，绝对定位覆盖在按钮上 */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: brown; /* 褐色背景 */
  opacity: 0; /* 默认透明度为0，即不显示蒙版 */
  transition: opacity 0.3s; /* 平滑过渡效果 */
}

.modal-body {
  padding: 20px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  opacity: .75;
}

.modal-footer {
  border-top: 1px solid #e5e5e5;
}
</style>
