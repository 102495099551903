<template>
  <div class="main-footer" style="">
    <div class="daohang">
      <img src="@/assets/logo1.png" class="main-footer-logo" style="visibility: hidden;"/>
      <div class="lianjie">
        <!-- <a @click="goPage('/contact-us')" target="_self">联系我们</a> -->
        <a href="https://qcnx120ai68z.feishu.cn/share/base/form/shrcnPhSdUoXlXaVCGyZ9zJAD4d" target="_blank">商务合作</a>
        <a href="https://www.ihuaben.com" target="_blank" style="margin-left: 30px;margin-right: 30px;">友情链接: 话本小说</a>
        <!-- <a style="margin-left: 30px;margin-right: 30px;">话本小说</a> -->
        <a href="https://beian.miit.gov.cn" target="_blank">京ICP备2024077045号-2</a>
      </div>
      <div class="copyright">
        Copyright @ 2024 Maliwriter. All Rights Reserved
      </div>
    </div>
    <div class="lianxi">
        <div>
          <el-image class="qrcode" fit="fill" src="https://maliwriter.com/static/qrcoe-1.png"></el-image>
          <div class="lianxi-text">官方小红书</div>
        </div>
        <div>
          <el-image class="qrcode" fit="fill" src="https://maliwriter.com/static/qrcoe-2.png"></el-image>
          <div class="lianxi-text">微信公众号</div>
        </div>
        <div>
          <el-image class="qrcode" fit="fill" src="https://maliwriter.com/static/qrcoe-3.png"></el-image>
          <div class="lianxi-text">微信官方群</div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      user: {}
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    goPage(path) {
      console.log(path)
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 768px) {
  .main-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    // max-width: 1400px;
    // min-width: 1024px;
    // width: 100%;
    // margin: 0 auto;
    &-logo {
      width: 13.3rem;
    }
    .daohang {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #666;
      text-align: left;
      .lianjie {
        margin-top: 2.2rem;
        margin-bottom: 1rem;
        color: #ffffff;
        a {
          text-decoration: none;
        }
        a:hover {
          color: #366ef4;
        }
      }
    }
    .lianxi {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: .5rem;
      text-align: center;
      &-text {
        padding: 0.5rem 0;
        color: #ffffff;
        font-size: 12px;
      }
      .qrcode {
        width: 80px;
        height: 80px;
        border-radius: 6px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .main-footer {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    width: 100%;
    &-logo {
      width: 8rem;
    }
    .daohang {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #666;
      text-align: left;
      .lianjie {
        margin-top: 2.2rem;
        margin-bottom: 1rem;
        color: #ffffff;
        a {
          text-decoration: none;
        }
      }
    }
    .lianxi {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: .5rem;
      text-align: center;
      margin-top: 2rem;
      &-text {
        padding: 0.5rem 0;
        color: #ffffff;
        font-size: 12px;
      }
      .qrcode {
        width: 80px;
        height: 80px;
        border-radius: 6px;
      }
    }
  }
}
</style>
