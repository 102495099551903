import axios from 'axios'
import { Message, Loading } from 'element-ui'
import router from '@/router'
import store from '@/store'
let loading // 定义 loading 变量

function startLoading() { // 使用 Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading() { // 使用 Element loading-close 方法
  loading && loading.close()
}

function relogin() { // 使用 Element loading-close 方法
  // 清除
  store.commit('clearCurrentState', null);
  router.push('/')
}
// 自定义 API URL, 如果使用代理, 需要注释掉
axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL

axios.startLoading = startLoading
axios.endLoading = endLoading
// 请求拦截  设置统一 header
axios.interceptors.request.use(config => {
  axios.isLoading && startLoading()
  if (localStorage.lyToken) { config.headers.Authorization = 'Bearer ' + localStorage.lyToken }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截  401 token 过期处理
axios.interceptors.response.use(response => {
  axios.isLoading && endLoading()
  const res = response.data
  if (res.code === 100002) {
    // Message({
    //   message: res.msg || 'Error',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    relogin()
  }else if (res.code === 200002) {
    // Message({
    //   message: res.msg || 'Error',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    relogin()
  }else if (res.code === 200004 || res.code === 200001) {
    // Message({
    //   message: res.msg || 'Error',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    relogin()
  }else if (res.code !== 0) {
    Message({
      message: res.msg || 'Error',
      type: 'error', customClass: 'message-custom-class',
      duration: 5 * 1000
    })
  }
  return response
}, error => {
  // 错误提醒
  endLoading()
  const { status } = error.response
  console.log(error,">>>")
  if (status == 401) {
    Message.error("无权限")
    relogin()
  } else {
    Message({
      message: error.message,
      type: 'error', customClass: 'message-custom-class',
      duration: 5 * 1000
    })
  }

  return Promise.reject(error)
})

export default axios
