import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Index from './views/index.vue'
import NotFound from './views/404'
import Login from './views/login'
import Home from './views/home'
// bookbreakdown
import BookbreakdownPage from '@/views/bookbreakdown/index'

// Workspace
import WorkspaceHome from '@/views/workspace/home'
import WorkspaceGenbyoutlinePage from '@/views/workspace/genbyoutline'
import WorkspaceGenbyoutlinePage2 from '@/views/workspace/genbyoutline2'
import WorkspaceGenbyoutlinePage3 from '@/views/workspace/genbyoutline3'
import WorkspaceGenbyoutlinePage4 from '@/views/workspace/genbyoutline4'

import WorkspaceGenbyideaPage from '@/views/workspace/genbyidea'
import WorkspaceGenbyideaPage2 from '@/views/workspace/genbyidea2'
import WorkspaceGenbyideaPage3 from '@/views/workspace/genbyidea3'
import WorkspaceGenbyideaPage4 from '@/views/workspace/genbyidea4'

import WorkspaceGen from '@/views/workspace/gen'
import WorkspaceGen1 from '@/views/workspace/gen1'
import WorkspaceGen2 from '@/views/workspace/gen2'
import WorkspaceGen3 from '@/views/workspace/gen3'
import WorkspaceGen4 from '@/views/workspace/gen4'
import WorkspaceGen5 from '@/views/workspace/gen5'

import WorkspaceGencp from '@/views/workspace/gencp'

// cashier
import CashierIndex from '@/views/cashier/index'
import CashierSuccess from '@/views/cashier/success'
// account
import AccountOrders from '@/views/account/orders'
import AccountEnergies from '@/views/account/energies'

// 微信 公众号 菜单管理
import WeixinmenuPage from '@/views/weixin/menu/page'
// 微信 公众号 账号管理
import WeixinaccountPage from '@/views/weixin/account/page'

// 账号管理
import AccountPage from '@/views/account/page'
// 个人信息
import MyInfo from '@/views/account/myinfo'

// 用户管理
import UserPage from '@/views/user/page'
// 达人审核
import UserKolPage from '@/views/user/kol/page'
// 商务合作
import UserCoPage from '@/views/user/co/page'
// 商户账号管理
import MerchantAccountPage from '@/views/account/merchant/page'
//充值中心
import VoucherPage from '@/views/voucher/page'
// 店铺
import OrganizationPage from '@/views/organization/page'
// 品类
import CategoryPage from '@/views/category/page'
// 菜单
import DishPage from '@/views/dish/page'
// 套餐
import SetmealPage from '@/views/setmeal/page'
// 服务
import ServicePage from '@/views/service/page'
// 订单
import OrderPage from '@/views/order/page'
// 平台服务
import OurServicePage from '@/views/ourservice/page'
// 部门管理
import DepartmentPage from '@/views/department/page'
// 应用管理
import ApplicationPage from '@/views/application/page'
// 租户管理
import TenantPage from '@/views/tenant/page'
// 角色管理
import RolePage from '@/views/role/page'
// 资源管理
import ResourcePage from '@/views/resource/page'
import ResourceGrant from '@/views/resource/grant'
// 任务管理
import TaskPage from '@/views/task/page'

// 天职师大 专项工作管理
import TutePage from '@/views/tute/work/page'

// 系统设置
import SystemConfigPage from '@/views/system/config/page'

// 官网管理
import HomePagePage from '@/views/homepage/page'
import MerchantBannerPage from '@/views/banner/merchant/page'
import StrategyPage from '@/views/banner/strategy/page'
import KoltipsPage from '@/views/banner/koltips/page'
import CoinPage from '@/views/banner/coin/page'
import TeamPage from '@/views/banner/team/page'
import ShoprulesPage from '@/views/banner/shoprules/page'
import BannerCoPage from '@/views/banner/co/page'
import KolPage from '@/views/banner/kol/page'
import BannerPage from '@/views/banner/page'
import ArticlePage from '@/views/article/page'
import CulturePage from '@/views/culture/page'
import GoodsPage from '@/views/goods/page'
import CertificatePage from '@/views/certificate/page'
import EvaluationPage from '@/views/evaluation/page'
import FeedbackPage from '@/views/feedback/page'
import WithdrawPage from '@/views/withdraw/page'

//agent
import StoryCharacterNamePage from '@/views/storycharactername/page'
import StoryCharacterPage from '@/views/storycharacter/page'
import StorySynesthesiaElementPage from '@/views/storysynesthesiaelement/page'
import StoryCpPage from '@/views/storycp/page'
import StoryLinePage from '@/views/storyline/page'
import AgentPage from '@/views/agent/page'
import WorkflowPage from '@/views/workflow/page'
import KnowledgeBasePage from '@/views/knowledgebase/page'
import FilePage from '@/views/file/page'

import Test from './views/test'

// ICP备案临时页面
import Contact from '@/views/icp/contact'
import CoWork from '@/views/icp/work'
import News from '@/views/icp/news'
import AboutUs from '@/views/icp/about_us'
import PayError from '@/views/icp/pay_error'

Vue.use(Router)

// 避免重复点击路由报错 Avoided redundant navigation to current location
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '*', name: '/404', component: NotFound },
    { path: '/test', name: 'test', component: Test },
    { path: '/tute', name: 'TutePage', component: TutePage },

    // writer [hide menu]
    { path: '/workspace/genbyoutline', name: 'WorkspaceGenbyoutlinePage', component: WorkspaceGenbyoutlinePage },
    { path: '/workspace/genbyoutline2', name: 'WorkspaceGenbyoutlinePage2', component: WorkspaceGenbyoutlinePage2 },
    { path: '/workspace/genbyoutline3', name: 'WorkspaceGenbyoutlinePage3', component: WorkspaceGenbyoutlinePage3 },
    { path: '/workspace/genbyoutline4', name: 'WorkspaceGenbyoutlinePage4', component: WorkspaceGenbyoutlinePage4 },

    { path: '/workspace/genbyidea', name: 'WorkspaceGenbyideaPage', component: WorkspaceGenbyideaPage },
    { path: '/workspace/genbyidea2', name: 'WorkspaceGenbyideaPage2', component: WorkspaceGenbyideaPage2 },
    { path: '/workspace/genbyidea3', name: 'WorkspaceGenbyideaPage3', component: WorkspaceGenbyideaPage3 },
    { path: '/workspace/genbyidea4', name: 'WorkspaceGenbyideaPage4', component: WorkspaceGenbyideaPage4 },

    // ICP
    { path: '/news', name: 'News', component: News },
    { path: '/contact-us', name: 'Contact', component: Contact },
    { path: '/co-work', name: 'CoWork', component: CoWork },
    { path: '/about-us', name: 'AboutUs', component: AboutUs },
    { path: '/icp-pay', name: 'PayError', component: PayError },

    { path: '/workspace/gen', name: 'WorkspaceGen', component: WorkspaceGen },
    { path: '/workspace/gen1', name: 'WorkspaceGen1', component: WorkspaceGen1 },
    { path: '/workspace/gen2', name: 'WorkspaceGen2', component: WorkspaceGen2 },
    { path: '/workspace/gen3', name: 'WorkspaceGen3', component: WorkspaceGen3 },
    { path: '/workspace/gen4', name: 'WorkspaceGen4', component: WorkspaceGen4 },
    { path: '/workspace/gen5', name: 'WorkspaceGen5', component: WorkspaceGen5 },

    { path: '/workspace/gencp', name: 'WorkspaceGencp', component: WorkspaceGencp },

    // cashier
    { path: '/cashier/index', name: 'CashierIndex', component: CashierIndex },
    { path: '/cashier/success', name: 'CashierSuccess', component: CashierSuccess },
    {
      path: '/',
      name: 'main',
      component: Index,
      children: [
        // maliwriter writer
        { path: '/home', name: 'home', component: WorkspaceHome },
        //bookbreakdown
        { path: '/bookbreakdown/index', name: 'BookbreakdownPage', component: BookbreakdownPage },
        // account
        { path: '/account/orders', name: 'AccountOrders', component: AccountOrders },
        { path: '/account/energies', name: 'AccountEnergies', component: AccountEnergies },

        { path: '/myinfo', name: 'MyInfo', component: MyInfo },
        { path: '/weixin/menu/page', name: 'WeixinmenuPage', component: WeixinmenuPage },
        { path: '/withdraw/page', name: 'WithdrawPage', component: WithdrawPage },
        { path: '/weixin/account/page', name: 'WeixinaccountPage', component: WeixinaccountPage },
        { path: '/account/page', name: 'AccountPage', component: AccountPage },
        { path: '/user/page', name: 'UserPage', component: UserPage },
        { path: '/application/page', name: 'ApplicationPage', component: ApplicationPage },
        { path: '/tenant/page', name: 'TenantPage', component: TenantPage },
        { path: '/organization/page', name: 'OrganizationPage', component: OrganizationPage },
        { path: '/category/page', name: 'CategoryPage', component: CategoryPage },
        { path: '/dish/page', name: 'DishPage', component: DishPage },
        { path: '/setmeal/page', name: 'SetmealPage', component: SetmealPage },
        { path: '/service/page', name: 'ServicePage', component: ServicePage },
        { path: '/order/page', name: 'OrderPage', component: OrderPage },
        { path: '/ourservice/page', name: 'OurServicePage', component: OurServicePage },
        { path: '/department/page', name: 'DepartmentPage', component: DepartmentPage },
        { path: '/resource/page', name: 'ResourcePage', component: ResourcePage },
        { path: '/resource/grant', name: 'ResourceGrant', component: ResourceGrant },
        { path: '/task/page', name: 'TaskPage', component: TaskPage },
        { path: '/account/merchant/page', name: 'MerchantAccountPage', component: MerchantAccountPage },
        { path: '/voucher/page', name: 'VoucherPage', component: VoucherPage },
        { path: '/role/page', name: 'RolePage', component: RolePage },
        { path: '/homepage/page', name: 'HomePagePage', component: HomePagePage },
        { path: '/banner/page', name: 'BannerPage', component: BannerPage },
        { path: '/banner/strategy/page', name: 'StrategyPage', component: StrategyPage },
        { path: '/banner/coin/page', name: 'CoinPage', component: CoinPage },
        { path: '/banner/team/page', name: 'TeamPage', component: TeamPage },
        { path: '/banner/shoprules/page', name: 'ShoprulesPage', component: ShoprulesPage },
        { path: '/banner/koltips/page', name: 'KoltipsPage', component: KoltipsPage },
        { path: '/banner/co/page', name: 'BannerCoPage', component: BannerCoPage },
        { path: '/banner/kol/page', name: 'KolPage', component: KolPage },
        { path: '/user/co/page', name: 'UserCoPage', component: UserCoPage },
        { path: '/user/kol/page', name: 'UserKolPage', component: UserKolPage },
        { path: '/banner/merchant/page', name: 'MerchantBannerPage', component: MerchantBannerPage },
        { path: '/article/page', name: 'ArticlePage', component: ArticlePage },
        { path: '/culture/page', name: 'CulturePage', component: CulturePage },
        { path: '/goods/page', name: 'GoodsPage', component: GoodsPage },
        { path: '/feedback/page', name: 'FeedbackPage', component: FeedbackPage },
        { path: '/certificate/page', name: 'CertificatePage', component: CertificatePage },
        { path: '/evaluation/page', name: 'EvaluationPage', component: EvaluationPage },
        { path: '/system/config/page', name: 'SystemConfigPage', component: SystemConfigPage },

        // agent
        { path: '/storycharactername/page', name: 'StoryCharacterNamePage', component: StoryCharacterNamePage },
        { path: '/storycharacter/page', name: 'StoryCharacterPage', component: StoryCharacterPage },
        { path: '/storyline/page', name: 'StoryLinePage', component: StoryLinePage },
        { path: '/storysynesthesiaelement/page', name: 'StorySynesthesiaElementPage', component: StorySynesthesiaElementPage },
        { path: '/storycp/page', name: 'StoryCpPage', component: StoryCpPage },
        { path: '/agent/page', name: 'AgentPage', component: AgentPage },
        { path: '/workflow/page', name: 'WorkflowPage', component: WorkflowPage },
        { path: '/file/page', name: 'FilePage', component: FilePage },
        { path: '/knowledgebase/page', name: 'KnowledgeBasePage', component: KnowledgeBasePage },
      ]
    }
  ]
})
// 白名单
const whitelist = [
  "/",
  "/bookbreakdown/index",
  "/test",
  "/cashier/index",
  "/cashier/success",
]

// 添加路由守卫
router.beforeEach((to, from, next) => {
  store.commit('setIsAuthenticated', store.token != '')//兼容之前的拼错记录
  const isAuthenticated = store.state.isAuthenticated
  const query = location.search
  console.log('from', from, 'to', to, 'isAuthenticated', isAuthenticated, 'query', query)
  // 记录当前页面
  localStorage.setItem('currentPath', to.path)

  if(query && query.length > 0){
    localStorage.setItem('urlquery', query)
  }

  if (whitelist.indexOf(to.path) === -1) {
      isAuthenticated ? next() : next('/' + query);
    } else {
      next();
    }
})

export default router
