<template>
  <div class="" style="background: #F0F1FF; border-radius: 10px;
                        padding: 1rem;  text-align: justify;font-weight: 400;
                        font-size: 12px;margin-right: 1rem;
                        color: rgba(0,0,0,0.6);
                        line-height: 18px;">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <b style="display: flex;align-items: center;"><img style="height: 12px; margin-right: 5px;" src="@/assets/tishi-icon.png" />重要提示:</b><i :class="{'el-icon-arrow-up': isCollapsed, 'el-icon-arrow-down': !isCollapsed}" class="pointer" @click="toggleVisibility"></i>
    </div>
    <div class="" v-if="!isCollapsed">
      * 码哩写作无法保证精确无误，成文后务必二次精修。<br>
      * 大纲若存在语句歧义或表达模糊，生成的正文会有很大偏差。<br>
      * 大纲表达更精确细致，交代清楚前因后果，成文效果会更好！<br>
      * 多生成几次，选择最满意的版本<br>
      * 永远记住：你独有的创造力才能发挥码哩写作最大潜力<br>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WriteTips',
  props: {

  },
  data() {
    return {
      message: '',
      isCollapsed: false // 控制内容是否收起
    }
  },
  computed: {

  },
  created() {

  },
  beforeDestroy() {

  },
  methods: {
    toggleVisibility() {
      this.isCollapsed = !this.isCollapsed; // 切换收起状态
    }
  }
}
</script>
<style>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #333;
  margin: 0 5px;
  animation: loading 1s infinite ease-in-out alternate;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading {
  from {
    transform: scale(1);
    opacity: 0.2;
  }
  to {
    transform: scale(1.5);
    opacity: 1;
  }
}
</style>
