<template>
  <div class="" ref="llm">
    <!-- 触发按钮 -->
    <el-button @click="showModal = true">打开模态窗口</el-button>

    <!-- 自定义模态窗口 -->
    <CustomModal
      :visible="showModal"
      title="自定义模态窗口"
      @update:visible="showModal = $event"
      @confirm="handleConfirm">
      <p>这里是模态窗口的内容。</p>
      <!-- 你可以在这里放置任何你需要的内容 -->
    </CustomModal>

    <div class="container">
      <div class="stretch"></div>
    </div>

    <div v-html="msg"></div>
    <el-button type="primary" @click="llmstream">sse</el-button>
    <el-button type="primary" @click="log">log</el-button>
    <el-button type="primary" @click="message">message</el-button>
    <el-button type="primary" @click="sendTips">sendTips</el-button>

    <!-- display: grid; -->
    <div v-if="false" class="table-container1">
      <!-- 动态添加单元格 -->
      <div class="table-cell1">
        <div class="content">内容1</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容2</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容3</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容4</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容11</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容12</div>
      </div>
     <div class="table-cell1">
        <div class="content">内容13</div>
      </div>
      <div class="table-cell1">
        <div class="content">内容14</div>
      </div>
      <!-- 更多单元格 -->
    </div>

<!--    <div class="" style="position: relative;">
      码哩写作1
      <div class="filter-blur">

      </div>
      码哩写作2
    </div> -->

    <!-- <VueDragResize :isActive="true" :w="200" :h="200" v-on:resizing="resize" v-on:dragging="resize"> -->
<!--    <VueDragResize :isActive="true"  :isResizable="false">
        <h3>Hello World!</h3>
        <p>{{ top }} х {{ left }} </p>
        <p>{{ width }} х {{ height }}</p>
    </VueDragResize> -->

    <!-- video -->
    <!-- <video-player ref="videoPlayer" style=""
           :options="playerOptions"
           title="码哩写作"
           @play="onPlayerPlay($event)"
           @pause="onPlayerPause($event)"
           @ended="onPlayerEnded($event)"
           @loadeddata="onPlayerLoadeddata($event)"
           @waiting="onPlayerWaiting($event)"
           @playing="onPlayerPlaying($event)"
           @timeupdate="onPlayerTimeupdate($event)"
           @canplay="onPlayerCanplay($event)"
           @canplaythrough="onPlayerCanplaythrough($event)"
           @statechanged="playerStateChanged($event)"
           @ready="playerReadied">
    </video-player> -->
    <!-- video-end -->

    <div class="animation-container">
      <img src="" alt="Animation Frame" class="animation-frame">
    </div>

    <div style="position: relative; width: 200px; height: 200px;">
      <!-- <frame-player ref="theFramePlayer"/> -->
    </div>

    <LlmSkeleton />

  </div>
</template>
<script>
import FramePlayer from 'vue-frame-player'
import sse from '@/utils/llmstream'
import { create } from '@/api/statlog'
import VueDragResize from 'vue-drag-resize';
import { videoPlayer } from 'vue-video-player'
import LlmSkeleton from '@/components/LlmSkeleton'
import { EventBus } from '@/utils/eventbus';
import CustomModal from '@/components/CustomModal.vue';
export default {
  components: {
    VueDragResize,FramePlayer,LlmSkeleton,CustomModal
  },
  data() {
    return {
      msg: '',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      showModal: false,
      playerOptions: {
        // component options
        start: 0,
        playsinline: false,
        // videojs options
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          // src: "https://image.uc.cn/s/uae/g/3o/broccoli/resource/202408/45060e5781c02920.mp4"
          // src: "https://maliwriter.com/static/homepage.mp4"
          src: "https://maliwriter.com/static/homepage.webm"
        }],
        // poster: "/static/images/author.jpg",
      },
    }
  },
  mounted() {
    // document.addEventListener("DOMContentLoaded", function() {
    //   var frames = [];
    //   for(let i=6000;i<6194;i++){
    //     frames.push(`https://maliwriter.com/static/png/${i}.png`)
    //   }
    //   console.log('frames', frames)
    //   var frameCount = frames.length;
    //   var currentFrame = 0;

    //   var imgElement = document.querySelector('.animation-frame');

    //   function updateFrame() {
    //     imgElement.src = frames[currentFrame];
    //     currentFrame = (currentFrame + 1) % frameCount;
    //   }

    //   // 每100毫秒更新一次帧（根据需要调整时间）
    //   setInterval(updateFrame, 100);
    // });
    const frames = [];
    for(let i=6000;i<6194;i++){
      frames.push(`https://maliwriter.com/static/png/${i}.png`)
    }
    // const player = this.$refs.theFramePlayer;
    //   player
    //   .init( {
    //     imageMode: 'visible',
    //     playMode: 'loop',
    //     length: 194,
    //     // initialImages: ( i, length ) => require( `../assets/bg/${i + 1}.jpg` ),
    //     initialImages: frames,
    //     playStep: 1,
    //     playSpeed: 24,
    //     autoplay: true,
    //     preload: true,
    //   } );

      // player
      // .on('play', () => console.log('frame player played!'))
      // .on('pause', () => console.log('frame player paused!'))
      // .on('ended', () => console.log('frame player ended!'))
      // .on('update', (current) => console.log('frame player update frame ', current))
      // .play()

      // let storyChapterOutline = ```
      // 上半部分：
      // 主角叶天一次登山过程中，遇险穿越到了四极洲的东州的某个小村庄——明日村。被村里的人所救下，并生活在那里。
      // ---
      // 下半部分：
      // 一次村里遭到魔兽袭击，村民死伤无数。只有外出洗澡的叶天幸免于难。这时村长才道出魔兽袭击他们的原因是因为村里曾住过的高人东方道人所留下来的修炼中阶功法。村长迫不得已把它赠与叶天，让叶天带着他去找东方家族前来搭救。
      // ```

      // let storyChapterOutline = `
      // ```
      // 一次村里遭到魔兽袭击，村民死伤无数。只有外出洗澡的叶天幸免于难。这时村长才道出魔兽袭击他们的原因是因为村里曾住过的高人东方道人所留下来的修炼中阶功法。村长迫不得已把它赠与叶天，让叶天带着他去找东方家族前来搭救。
      // ```
      // `
      // console.log(storyChapterOutline);
  },
  methods: {
    handleConfirm(){
      alert('模态窗口确认');
    },
    llmstream() {
      sse("1815703365829668865", {articleId: '1856219105523118081'}, this.handleMsg).then((result)=>{
        console.log('result', result);
      })
    },
    sendTips(){
      let tips = ''
      // 60s、提示用户：糟糕！脑电波链接失败！被神秘信号干扰，请尝试重新生成，或稍后重试！
      // 50S、提示用户：码哩码哩哄～(╯‵□′)╯︵┻━┻（持续念咒中···）
      // 40S、提示用户：码哩码哩哄～(╯°□°）╯︵ ┻━┻（念咒中···）
      // 30S、提示用户：共脑中(●--●)···
      // 20S、提示用户：码哩正在读取你的想法(●°u°●)​ 」···
      // 10S、提示用户：码哩正在打开脑电波，接受信号中O(∩_∩)O~···
      const duration = Math.floor(Math.random() * 6);
      console.log('duration',duration);
      if(duration == 5){
        tips = '糟糕！脑电波链接失败！被神秘信号干扰，请尝试重新生成，或稍后重试！'
      }else if(duration == 4){
        tips = '码哩码哩哄～(╯‵□′)╯︵┻━┻（持续念咒中）'
      }else if(duration == 3){
        tips = '码哩码哩哄～(╯°□°）╯︵ ┻━┻（念咒中）'
      }else if(duration == 2){
        tips = '提示用户：共脑中(●--●)'
      }else if(duration == 1){
        tips = '码哩正在读取你的想法(●°u°●)​ 」'
      }else if(duration == 0){
        tips = '码哩正在打开脑电波，接受信号中O(∩_∩)O~'
      }
      console.log('tips',tips);
      EventBus.$emit('llm-tips', tips);
    },
    handleMsg(msg){
      // console.log('msg', msg);
      this.msg = msg
    },
    message(){
      this.$message({
        dangerouslyUseHTMLString: true,
        message: '抱歉，AI忙不过来啦，请您重新生成试试哦，如有其它问题可<a style="color: #0052D9;font-weight: bold;" href="https://work.weixin.qq.com/kfid/kfc73d694476dbadaf1" target="_blank">联系客服</a>',
        type: 'error',
        duration: 8 * 1000
      })
      // let result = ''
      //  let temp = result
      //  result = []
      // if(temp != '' && (!result || result == '' || result.length == 0)){
      //   this.$message.warning('JOSN 解析异常，请重新生成')
      //   return
      // }
    },
    log(){
      create({predicate: 'BAIDU_TUIGUANG', objectText: 'querystring'});
    },
    resize(newRect) {
        this.width = newRect.width;
        this.height = newRect.height;
        this.top = newRect.top;
        this.left = newRect.left;
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    onPlayerEnded(player) {
      // console.log('player pause!', player)
      player.play()
    },
    onPlayerLoadeddata(player) {
      // console.log('player pause!', player)
    },
    onPlayerWaiting(player) {
      // console.log('player pause!', player)
    },
    onPlayerPlaying(player) {
      // console.log('player pause!', player)
    },
    onPlayerTimeupdate(player) {
      // console.log('player pause!', player)
    },
    onPlayerCanplay(player) {
      // console.log('player pause!', player)
    },
    onPlayerCanplaythrough(player) {
      // console.log('player pause!', player)
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    // player is ready
    playerReadied(player) {
      console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
      player.play()
    }
  }
};
</script>
<style scoped>
/deep/ video {
  mix-blend-mode: screen;
  /* background: #000000; */
  background: transparent;
}
.container {
    width: 100%; /* 或者你想要设置的宽度 */
    height: 400px; /* 或者你想要设置的高度 */
    overflow: hidden;
    position: relative;
    background-image: url('../assets/newer-gift-v2.png');/* 替换为你的图片地址 */
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom center;
}

.stretch {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%; /* 或者你想要拉伸的部分的高度 */
  background-image: url('../assets/newer-gift-v2.png');
  background-repeat: repeat-x;
  background-size: 100% auto;
  background-position: bottom center;
}
.frame-player {
    position: absolute;
    width: 768px;
    height: auto;
}
.filter-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  filter: blur(10px);
  color: #FFFFFF;
  z-index: -1;
}
/* 容器样式 */
.table-container1 {
  display: grid;
  grid-template-rows: repeat(2, 1fr); /* 2行，每行高度相等 */
  grid-auto-flow: column; /* 允许项目填充更小的空格 */
  /* grid-auto-columns: repeat(1, 1fr); */
  gap: 8px; /* 单元格间隙 */
  /* width: auto; */
  overflow-y: hidden;
  overflow-x: auto;
  background: pink;
}

/* 单元格样式 */
.table-cell1 {
  display: flex;
  border: 1px solid #000; /* 边框样式 */
  padding: 8px; /* 单元格内边距 */
  box-sizing: border-box; /* 边框和内边距包含在宽度内 */
  width: 10rem;
}

/* 内容样式，用于调整内容以适应单元格宽度 */
.content {
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
}


/* png iframe */
.animation-container {
  position: relative;
  width: 100%; /* 根据你的帧尺寸调整 */
  height: 100%; /* 根据你的帧尺寸调整 */
}

.animation-frame {
  width: 100%;
  height: auto;
  display: block;
}

/* 定义关键帧动画 */
@keyframes play-sequence {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 应用动画到图像 */
.animation-frame {
  animation: play-sequence 30ms steps(1) infinite;
}
/* png iframe end */
</style>
