<template>

    <!-- 登录/注册 弹窗 -->
    <el-dialog
      class="custom-dialog"
      ref="dialog-login"
      :visible.sync="showLoginDialogFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="false"
      :destroy-on-close="true"
      :show-close="true"
      :close-on-click-modal="true"
      :wrapperClosable="true"
      :width="drawerWidth"
      :top="dialogTop"
      @close="close"
      >
          <div style="font-size: 1.5rem; font-weight: 400;">登录到</div>
          <div style="font-weight: 600;color: #366EF4; font-size: 1.5rem; margin-bottom: 1rem;">MaliMali 码哩写作</div>
          <p style="color: rgba(41,40,43,0.6);font-size: 14px;margin: 0.3rem 0">你的创作金手指！</p>
          <el-form :model="form" ref="formRef" :rules="rules" class="login-form" label-position="left" :hide-required-asterisk='true'>
            <el-form-item prop="username" class="mb">
              <el-input v-model="form.username" prefix-icon="el-icon-phone" placeholder="请输入手机号" autocomplete="on" class="custom-input-text" clearable />
            </el-form-item>
            <el-form-item prop="verifyCode" class="mb">
              <el-input v-model="form.verifyCode" ref="verificationCodeInput" placeholder="请输入验证码" class="custom-input-text" clearable>
                <template slot="append">
                    <el-button @click="startCountdown" :loading="isGetCodeLoading" :disabled="getCaptchaDisabled" class="yanzhengma">
                      {{ getCaptchaDisabled ? `${timeLeft}秒后重发` : '获取验证码' }}
                    </el-button>
                  </template>
                </el-input>
            </el-form-item>
            <el-button style="border-radius: 6px !important;" class="mt w-full" type="primary" @click="handleLogin()" @keydown.native="handleKeyPress()">注册/登录</el-button>
          </el-form>
    </el-dialog>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
import * as LoginApi from '@/api/auth'
export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      form: {
        applicationId: 0,
        username: '',
        phone: '',
        verifyCode: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入手机号', trigger: ['blur','change'] },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码格式', trigger: ['blur','change'] }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: ['blur','change'] },
          { min: 6, max: 6, message: '验证码长度必须为6位', trigger: ['blur','change'] }
        ]
      },
      isGetCodeLoading: false,
      getCaptchaDisabled: false,
      timeLeft: 60, // 初始倒计时时间
      intervalId: null, // 用于存储定时器的 ID
      account: {}
    }
  },
  computed: {
    ...mapState(['user', 'story', 'showLoginDialogFlag', 'urlquery']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '80%' : window.innerWidth <= 1200 ? '30%' : '30%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "20vh" : '10vh';
    },
    dialogTitle () {
      return this.story.id ? '故事设置' : '新建故事'
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  created() {
    document.addEventListener('keydown', this.handleKeyPress);
  },
  mounted() {
    console.log('query', location.search)
    window.addEventListener('resize', this.forceUpdate);
  },
  methods: {
    ...mapMutations(['setStory', 'setUser', 'setToken', 'setShowLoginDialogFlag', 'setShowVideoFlag', 'setIsAuthenticated']),
    close(){
      this.setShowLoginDialogFlag(false);
      // this.setShowVideoFlag(true)
    },
    handleKeyPress(event) {
      // 检查按下的键是否是回车键
      if (event.key === 'Enter') {
        // 执行你想要的操作，例如提交表单
        this.handleLogin();
      }
    },
    handleLogin() {
      // let md5 = require('md5')
      let params = Object.assign({}, this.form)
      params.urlquery = localStorage.urlquery
      // params.verifyCode = md5(this.form.verifyCode)
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          LoginApi.loginByFrom(params)
            .then(res => {
              // 登录成功
              if (res.data.code === 0) {
                this.account = res.data.data
                console.log('handleLogin', this.account);
                localStorage.setItem('lyToken', this.account.token)
                localStorage.setItem('lyAccount', JSON.stringify(this.account))

                this.$store.commit('setToken', this.account.token)
                this.$store.commit('setUser', this.account)

                // 登录状态
                this.$store.commit('setIsAuthenticated', this.account.token != '')
                this.$router.push('/home')
                // 调用 this.handleNewerGift();
                this.$parent.handleNewerGift();
                this.close()
                this.form.username = ''
                this.form.phone = ''
                this.form.verifyCode = ''
              }
            })
        } else {
          console.log('表单验证失败');
          return false;
        }
      });
    },
    startCountdown() {
      this.isGetCodeLoading = true
      if (this.intervalId) {
        clearInterval(this.intervalId); // 如果已经有一个定时器在运行，先清除
      }
      this.$refs.formRef.clearValidate('username');
      this.$refs.formRef.validateField('username', (message) => {
        console.log('message', message);
        if (!message) {
          this.form.phone = this.form.username

          LoginApi.getVerifycode(this.form)
            .then(res => {
              // 获取成功
              if (res.data.code === 0) {
                this.getCaptchaDisabled = true;
                this.isGetCodeLoading = false
                this.intervalId = setInterval(() => {
                  if (this.timeLeft > 0) {
                    this.timeLeft--; // 每秒减少 1
                  } else {
                    clearInterval(this.intervalId); // 倒计时结束，清除定时器
                    this.timeLeft = 60; // 重置倒计时时间
                    this.getCaptchaDisabled = false;
                  }
                }, 1000);

                this.$nextTick(() => {
                  this.$refs.verificationCodeInput.focus();
                });
              }else{
                this.getCaptchaDisabled = false;
                this.isGetCodeLoading = false
              }
            })
        } else {
          console.log('手机号码验证失败');
          this.getCaptchaDisabled = false;
          this.isGetCodeLoading = false
          return false
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
.login-form{
  color: #ffffff;
  padding: 1rem 0;
}
/deep/ .el-form-item__label-wrap .el-form-item__label {
  color: #ffffff !important;
}
.mt {
  margin-top: 1rem !important;
}
.mb {
  margin-bottom: 1rem !important;
}
/deep/  .yanzhengma.el-button {
  background-color: #ffffff; /* 设置背景颜色 */
  border: none; /* 设置边框宽度、样式和颜色 */
  box-shadow: none;
  cursor: pointer; /* 鼠标悬停时显示指针手势 */
  // border: 1px solid #5C5A5A;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
/deep/ .custom-input-text .el-button--default {
  background-color: #ffffff; /* 设置背景颜色 */
}
/deep/ .custom-dialog .el-dialog__wrapper {
  background-color: rgba(0, 0, 0, 0.01) !important;
}
</style>
